import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
  FormControl,
} from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { userData } from "./userData.model";
import { UserType } from "../core/userType.model";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { SortEvent, SortDirection } from "@aposin/ng-aquila/table";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { throwError } from "rxjs";
@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.css"],
})
export class UserlistComponent implements OnInit {
  userModalOpen: boolean = false;
  isSuccess: boolean = false;
  confirmModalOpen: boolean = false;
  userForm: FormGroup;
  clientId: number;
  EDIT_FLAG: boolean;
  userData: userData = new userData();
  userId;
  userName;

  userDetails = {};
  userList = [];
  userTypeList = [];
  zoneList = [];
  _asmcount: number = 0;
  _rsmcount: number = 0;
  _nsmcount: number = 0;
  _apiUrl: string;
  userRole: string;
  userBaseTypeId: any;
  clientList: any;
  superUsersList: any;

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  BaseTypeId: number;
  Id: number;
  errorMessage: string;
  closed: boolean;
  userTypeCount: number;
  allowedUserCount: number;
  private _financecount: any;
  prevPage() {
    this.pageNumber--;
    this.getUserList();
  }

  nextPage() {
    this.pageNumber++;
    this.getUserList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getUserList();
  }
  /**Pagination end */
  /** sorting starts here */

  sortTable(sort: SortEvent) {
    //Sorts the table data by a certain category.
    this.userList = this.userList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,

    private messageToastService: NxMessageToastService
  ) {
    this._asmcount = appConfig.asmCount;
    this._rsmcount = appConfig.rsmCount;
    this._nsmcount = appConfig.nsmCount;
    this._financecount = appConfig.financeCount;
    this._apiUrl = appConfig.baseUrl;
    this.userName = localStorage.getItem("USER_NAME");
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userRole = atob(localStorage.getItem("userRole"));
    this.BaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
  }

  ngOnInit() {
    this.getZoneList();
    this.userFormBuilder();
    this.getUserList();
    if (this.userRole == "SysAdmin") this.getClientList();
  }

  userFormBuilder() {
    this.userForm = new FormBuilder().group({
      firstName: [this.userData.firstName, Validators.required],
      lastName: [this.userData.lastName, Validators.required],
      userTypeId: [this.userData.userTypeId, Validators.required],
      emailId: new FormControl(this.userData.emailId, [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.EMAIL_REGEX),
      ]),
      mobileNumber: new FormControl(this.userData.mobileNumber, [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ]),
      zoneIds: [this.userData.zoneIds, Validators.required],
      //dealerCode: [this.userData.dealerCode, Validators.required],
      //dealerAddress: [this.userData.dealerAddress, Validators.required],
      clientId: this.clientId,
      isActive: 1,
      password: [this.userData.password],
      createdDate: this.userData.createdDate,
      createdBy: this.userName,
      updatedBy: this.userName,
      updatedDate: this.userData.updatedDate,
      lastLoginDate: this.userData.lastLoginDate,
      attemptCount: this.userData.attemptCount,
      userRole: this.userData.userRole,
      userId: this.userData.userId,
      superUser: [this.userData.superUser, Validators.required],
      newPassword: ["", Validators.pattern(MESSAGE_CONSTS.PASSWORD_REGEX)],
      identifier: this.userData.identifier,
    });
  }
  get f() {
    return this.userForm.controls;
  }

  /**
   * listing all the users
   */
  getUserList() {
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/Users" +
          "?pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm
      )
      .subscribe(
        (response) => {
          if (response) {
            debugger
            this.userList = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
            this.pageNumber=pagingProp.CurrentPage;
            this.pageSize=pagingProp.PageSize;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  /**
   * Add,edit user modal open and close
   */
  toggleModal(EDIT: boolean, userId) {
    this.EDIT_FLAG = EDIT;
    this.userId = userId;
    this.userModalOpen = !this.userModalOpen;
    this.isSuccess = false;
    this.getZoneList();
    if (this.userModalOpen) {
      this.getUserTypeList();
      if (this.EDIT_FLAG) {
        this.getUserDetails(this.userId);
      }
    } else {
      this.userData = new userData();
      this.userFormBuilder();
      this.isSuccess = true;
    }
  }
  getUserCount(BaseTypeId: number) {
    this.authService
      .get(this._apiUrl + `/api/v1/Users/GetUserCount/${BaseTypeId}`)
      .subscribe(
        (response) => {
          this.userTypeCount = parseInt(response);
          switch (BaseTypeId) {
            case UserType.BD:
              this.allowedUserCount = this._asmcount;
              break;
            case UserType.RSM:
              this.allowedUserCount = this._rsmcount;
              break;
            case UserType.NSM:
              this.allowedUserCount = this._nsmcount;
              break;
            case UserType.Finance:
              this.allowedUserCount = this._financecount;
              break;
            default:
              break;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  /**
   *  Listing user types
   */
  getUserTypeList() {
    this.authService
      .get(this._apiUrl + "/api/v1/UserTypes/client/" + this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.userTypeList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  /**
   * change user role
   */
  onUserTypeIdChanges(id) {
    this.userData.userTypeId = id;

    this.authService
      .get(this._apiUrl + "/api/v1/UserTypes/baseUserType/" + id)
      .subscribe(
        (response) => {
          this.userBaseTypeId = response;
          this.getUserCount(this.userBaseTypeId);
          if (this.userBaseTypeId == UserType.BD)
            this.getUsersByBaseType(UserType.RSM);
          if (this.userBaseTypeId == UserType.Dealer)
            this.getUsersByBaseType(UserType.BD);

          if (this.userBaseTypeId == UserType.RSM) {
            this.userForm.get("superUser").clearValidators();
            this.userForm.get("superUser").updateValueAndValidity();
          }
          if (
            this.userBaseTypeId == UserType.NSM ||
            this.userBaseTypeId == UserType.AzPAdmin
          ) {
            this.userForm.get("zoneIds").clearValidators();
            this.userForm.get("zoneIds").updateValueAndValidity();
            this.userForm.get("superUser").clearValidators();
            this.userForm.get("superUser").updateValueAndValidity();
          }
        },
        (error) => {
          console.error(error);
        }
      );
    this.userFormBuilder();
  }
  /**
   * open confirmation pop up for activate/deactiate user
   */
  confirmModal(userId, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;

    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }

    this.userDetails = {
      id: userId,
      statusId: isActive,
    };
  }
  /**
   * close confirmation pop up for activate/deactiate user
   */
  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getUserList();
    this.userDetails = {};
  }
  /**
   *  activate/deactiate user
   *params: id,statusId
   */
  changeUserStatus() {
    this.authService
      .put(this._apiUrl + "/api/v1/Users/StatusUpdate", this.userDetails)
      .subscribe(
        (response) => {
          this.confirmModalClose();
        },
        (error) => {
          this.handleError(error);
          this.confirmModalClose();
        }
      );
  }

  /**
   *  user details
   *params: id
   */
  getUserDetails(userId) {
    this.authService.get(this._apiUrl + "/api/v1/Users/" + userId).subscribe(
      (response) => {
        if (response) {
          if (response.superUser) {
            this.onsuperUserIdChanges(response.superUser);
          } else {
            this.getZoneList();
          }
          this.userData = response;
          //this.userData.zoneIds = response.zoneIds[0];
          this.userBaseTypeId = this.userData.userBaseTypeId;
          this.onUserTypeIdChanges(this.userData.userTypeId);
          this.userFormBuilder();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * adding new user
   */
  addUser() {
    if (this.userTypeCount < this.allowedUserCount) {
      // var zones: Array<number> = this.userForm.get('zoneIds').value;
      // this.userForm.patchValue({
      //   zoneIds: zones[0] ? zones : zones[0]
      // });
      if (!this.userForm.get("superUser").value) {
        this.userForm.patchValue({
          superUser: 0,
        });
      }
      this.authService
        .post(this._apiUrl + "/api/v1/Users", this.userForm.value)
        .subscribe(
          (response) => {
            if (response) {
              this.getUserList();
              this.isSuccess = true;
            }
          },
          (error) => {
            console.error(error);
            this.handleError(error);
          }
        );
    } else {
      this.messageToastService.open(
        `More than ${this.allowedUserCount} users for this user role is not allowed`,
        {
          context: "info",
          duration: 5000,
        }
      );
    }
  }
  /**
   * updating  user
   */
  updateUser() {
    if (!this.userForm.get("superUser").value) {
      this.userForm.patchValue({
        superUser: 0,
      });
    }

    if (this.userForm.get("newPassword").value) {
      this.userForm.patchValue({
        password: this.userForm.get("newPassword").value,
      });
    }
    this.authService
      .put(this._apiUrl + "/api/v1/Users/" + this.userId, this.userForm.value)
      .subscribe(
        (response) => {
          this.getUserList();
          this.isSuccess = true;
        },
        (error) => {
          console.error(error);
          this.handleError(error);
        }
      );
  }

  getZoneList() {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/client/${this.clientId}?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getClientList() {
    this.authService.get(this._apiUrl + "/api/v1/Client").subscribe(
      (response) => {
        if (response) {
          this.clientList = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onClientChanges(clientid) {
    this.clientId = clientid;
    this.getUserTypeList();
    this.getUserListByClientt();
  }

  getUserListByClientt() {
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/Users" +
          "?pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm +
          "&clientId=" +
          this.clientId
      )
      .subscribe(
        (response) => {
          if (response) {
            this.userList = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  isShowZone() {
    let zoneApplicableUserTypes = [UserType.BD, UserType.Dealer, UserType.RSM];
    return zoneApplicableUserTypes.includes(this.userBaseTypeId);
  }

  getUsersByBaseType(baseUserType: number) {
    this.authService
      .get(this._apiUrl + `/api/v1/UserTypes/userBybaseType/${baseUserType}`)
      .subscribe(
        (response) => {
          if (response) {
            this.superUsersList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onsuperUserIdChanges(id: any) {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/GetZoneByUserId/${id}`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;
    }
    this.closed = false;
    this.messageToastService.open(this.errorMessage, {
      context: "info",
      duration: 5000,
    });
    return throwError(this.errorMessage);
  }
}
