import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
  FormControl,
} from "@angular/forms";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { PolicyStatus } from "../core/policyStatus.model";
import * as moment from "moment";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CustomErrorHandler } from "../core/custom-error-handler";

@Component({
  selector: "app-editpolicy",
  templateUrl: "./editpolicy.component.html",
  styleUrls: ["./editpolicy.component.css"],
})
export class EditpolicyComponent implements OnInit {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() id: string;
  sub;
  _apiUrl;
  clientId;
  userId;
  editPolicyForm: FormGroup;
  sellPolicyData: sellPolicyData = new sellPolicyData();
  stateList = [];
  cityList = [];
  modelList = [];
  colorList = [];
  planMasterList = [];
  expiryDate;
  effectiveDate;
  currentDate;
  isSuccess: boolean = false;
  policyStatus: any;
  policyDetails = {};
  userPermission: any = {};
  IsModified: boolean = false;
  planduration: number;
  userrole: string;
  modificationResponse: string;
  EnableClose: boolean= true;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.policyStatus = PolicyStatus;
    this.userrole = atob(localStorage.getItem("userRole"));
  }

  ngOnInit() {
    this.editPolicyFormBuild();
    this.getModels();
    this.getColors();
    this.getStateList();
    this.getPolicyDetails(this.id);
    this.setUserPermission();
  }

  close() {
    this.closeModal.emit(false);
  }

  setUserPermission() {
    this.authService.userPermissions().subscribe((response) => {
      if (response) {
        this.userPermission = response;
        this.checkPolicyEditableFeilds();
      }
    });
  }

  editPolicyFormBuild() {
    this.editPolicyForm = new FormBuilder().group({
      id: this.id,
      transactionId:this.id,
      vinChassisNo: [
        this.sellPolicyData.vinChassisNo,
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.VIN_REGEX)],
      ],
      licensePlateNo: [
        this.sellPolicyData.licensePlateNo?.toUpperCase(),
        [Validators.pattern(MESSAGE_CONSTS.NUMBER_PLATE_REGEX)],
      ],
      model: [this.sellPolicyData.model, [Validators.required]],
      colorName: [this.sellPolicyData.colorName, [Validators.required]],
      vehicleSaleDate: [
        this.sellPolicyData.vehicleSaleDate,
        [Validators.required],
      ],
      variant: this.sellPolicyData.variant,
      invoiceDate: { value: this.sellPolicyData.invoiceDate, disabled: true },
      otherDetails: { value: this.sellPolicyData.otherDetails, disabled: true },
      workshopId: this.sellPolicyData.workshopId,
      clientId: this.clientId,
      policyStatusId: this.sellPolicyData.policyStatusId,
      customerFirstName: [
        this.sellPolicyData.customerFirstName,
        Validators.required,
      ],
      customerMiddleName: this.sellPolicyData.customerMiddleName,
      customerLastName: [
        this.sellPolicyData.customerLastName,
        Validators.required,
      ],
      customerPhoneNo: this.sellPolicyData.customerPhoneNo,
      custGstNumber: this.sellPolicyData.custGstNumber,
      customerMobileNo: new FormControl(this.sellPolicyData.customerMobileNo, [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ]),
      customerEmail: new FormControl(this.sellPolicyData.customerEmail, [
        Validators.required,
        Validators.email,
      ]),
      customerCompany: this.sellPolicyData.customerCompany,
      customerAddress: new FormControl(this.sellPolicyData.customerAddress, [
        Validators.required,
      ]),
      state: this.sellPolicyData.state,
      city: this.sellPolicyData.city,
      stateId: this.sellPolicyData.stateId,
      cityId: this.sellPolicyData.cityId,
      pincode: [this.sellPolicyData.pincode, [Validators.required]],
      planId: { value: this.sellPolicyData.planId, disabled: true },
      policyName: { value: this.sellPolicyData.policyName, disabled: true },
      effectiveDate: this.sellPolicyData.effectiveDate,
      expiryDate: this.sellPolicyData.expiryDate,
      price: { value: this.sellPolicyData.price, disabled: true },
      gstPrice: { value: this.sellPolicyData.gstPrice, disabled: true },
      paymentMethod: {
        value: this.sellPolicyData.paymentMethod,
        disabled: true,
      },
      soldBy: { value: this.sellPolicyData.soldBy, disabled: true },
      unitPrice: { value: this.sellPolicyData.unitPrice, disabled: true },
      outstandingBal: {
        value: this.sellPolicyData.outstandingBal,
        disabled: true,
      },
      paymentDate: { value: this.sellPolicyData.paymentDate, disabled: true },
      policyNo: [
        this.sellPolicyData.policyNo,
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.PINCODE_REGEX)],
      ],
      policyRequestType: "Modify",
      dealerId: 0,
      remarks: ["", Validators.required],
      approvedBy: 0,
      updatedDate: [Date.now],
      status: this.sellPolicyData.policyStatusId,
    });
    this.getStateWiseCityList(this.sellPolicyData.stateId);
  }

  get f() {
    return this.editPolicyForm.controls;
  }
  getModels() {
    this.authService
      .get(this._apiUrl + "/api/v1/Models?pageNumber=-1")
      .subscribe(
        (response) => {
          if (response) {
            this.modelList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getColors() {
    this.authService
      .get(this._apiUrl + "/api/v1/Colors?pageNumber=-1")
      .subscribe(
        (response) => {
          if (response) {
            this.colorList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getStateList() {
    this.authService
      .get(this._apiUrl + `/api/v1/States?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getStateWiseCityList(stateId) {
    if (stateId == undefined || stateId == null) {
      return;
    }
    this.authService
      .get(this._apiUrl + "/api/v1/Cities/state/" + stateId)
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getPlanMasterList() {
    this.authService.get(this._apiUrl + "/api/v1/Plan?pageNumber=-1").subscribe(
      (response) => {
        if (response) {
          this.planduration = 0;
          this.planMasterList = response;
          if (
            this.sellPolicyData.planId != 0 &&
            this.sellPolicyData.planId != undefined
          ) {
            var selectedplan = this.planMasterList.filter((obj) => {
              if (parseInt(obj.unityPlanId) == this.sellPolicyData.planId)
                return obj;
            });
            this.planduration = parseInt(selectedplan[0].durationOfPlan);
            this.sellPolicyData.planId =
              selectedplan.length == 0 ? null : selectedplan[0].unityPlanId;
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getPolicyDetails(id) {
    this.authService
      .get(this._apiUrl + "/api/v1/PolicyDetails/" + id)
      .subscribe(
        (response) => {
          if (response) {
            this.sellPolicyData = response;
            this.effectiveDate = this.sellPolicyData.effectiveDate;
            this.expiryDate = this.sellPolicyData.expiryDate;
            this.currentDate = this.sellPolicyData.paymentDate;
            this.editPolicyFormBuild();
            this.getPlanMasterList();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  updatePolicy() {
    var result: any;
    if (
      this.userPermission.editPolicy &&
      this.userPermission.editPolicyApprove
    ) {
      // update directly if user has editpolicy and approve edit policy permissions
      this.editPolicyForm.controls["status"].setValue(
        this.policyStatus.Modified
      );
      this.editPolicyForm.controls["approvedBy"].setValue(1);
      this.authService
        .post(
          this._apiUrl + "/api/v1/PolicyRequestLogs",
          this.editPolicyForm.value
        )
        .pipe(
          map((response) => {
            result = response;
            this.isSuccess = true;
            this.IsModified = true;
            this.EnableClose = false;
            this.modificationResponse = "Policy modification request processing...";
          }),
          //update policy details in unity db
          mergeMap(() =>
            this.authService
              .put(this._apiUrl + "/api/v1/Unity/ModifyUnitydata/", result.transactionId)
              .pipe(
                catchError((error) =>
                  this.errorHandler.handleCustomError(
                    "Something went wrong modifing policy!"
                  )
                )
              )
          ),
          map((response) => {
            if (!response.success) {
              this.close();
              this.errorHandler.handleCustomError(response.responsemsg);
            }
            else
            {
              this.modificationResponse = "APD/Unity data updated...";
            }
          }),
          //update policy table directly
          mergeMap(() =>
            this.authService
              .put(
                this._apiUrl +
                  "/api/v1/PolicyDetails/ModificationApproval/" +
                  result.transactionId +
                  "/" +
                  this.id,
                ""
              )
              .pipe(
                catchError((error) =>
                  this.errorHandler.handleCustomError(
                    "Something went wrong modifing policy!"
                  )
                )
              )
          ),
          map((response) => {
            if (response.success) {
              this.modificationResponse = "Send for policy updation...";
            } else {
              this.close();
              this.errorHandler.handleCustomError(response.responsemsg);
            }
          }),
          // Trigger call to create modified policy document
          mergeMap(() =>
            this.authService
              .export(
                this._apiUrl +
                  `/api/v1/DownLoadPage/policyNo/${this.id}`
              )
              .pipe(
                catchError((error) =>
                  this.errorHandler.handleCustomInfo(
                    "Generating policy document is failed, Please download from Manage Policy !"
                  )
                )
              )
          ),
          map((response) => {
            if (response != null) {
              this.modificationResponse = "New Policy document generated...";
            }
          }),

          // Trigger call to send policy document
          mergeMap(() =>
            this.authService
              .get(
                this._apiUrl +
                  `/api/v1/PolicyDetails/sendModifiedPolicyMail/policyNo/${this.id}`
              )
              .pipe(
                catchError((error) =>
                  this.errorHandler.handleCustomInfo(
                    "Policy document failed to send via Email,Please Download Later!"
                  )
                )
              )
          ),
          map((response) => {
            if (!response.success) {
              this.close();
              this.errorHandler.handleCustomError(response.responsemsg);
            }
            else{
              this.EnableClose = true;
              this.modificationResponse = "Policy Modification Successfully Completed";
            }
          })
        )
        .subscribe(
          (response) => {},
          (error) => {
            this.close();
            //this.errorHandler.handleCustomError('Something went wrong modifing policy!')
            console.error(error);
          }
        );
    } else {
      this.editPolicyForm.controls["status"].setValue(
        this.policyStatus.ModificationApprovalPending
      );
      this.authService
        .post(
          this._apiUrl + "/api/v1/PolicyRequestLogs",
          this.editPolicyForm.value
        )
        .subscribe(
          (response) => {
            this.updatePolicyStatus();
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  updatePolicyStatus() {
    this.policyDetails = {
      id: this.id,
      statusId: this.policyStatus.ModificationApprovalPending,
    };
    this.authService
      .put(
        this._apiUrl + "/api/v3/PolicyDetails/StatusUpdate",
        this.policyDetails
      )
      .subscribe(
        (response) => {
          this.policyDetails = {};
          this.isSuccess = true;
        },
        (error) => {
          console.error(error);
          this.isSuccess = false;
        }
      );
  }

  onEffectiveDateChange() {
    //calculate new expiry date depending on date selected in effective date
    var new_effectivedate = new Date(
      this.editPolicyForm.controls.effectiveDate.value
    );
    var new_expirydate = new Date(
      new_effectivedate.getFullYear() + this.planduration,
      new_effectivedate.getMonth(),
      new_effectivedate.getDate()
    );
    this.editPolicyForm.controls["expiryDate"].setValue(moment(new_expirydate));
  }

  checkPolicyEditableFeilds() {
    if (
      this.userrole.toLowerCase() === "dealer" ||
      this.userrole.toLowerCase() === "workshop"
    ) {
      // check if user has editpolicy and approve edit policy permissions
      this.editPolicyForm.controls["vinChassisNo"].disable();
      this.editPolicyForm.controls["licensePlateNo"].disable();
      this.editPolicyForm.controls["vehicleSaleDate"].disable();
      this.editPolicyForm.controls["effectiveDate"].disable();
      this.editPolicyForm.controls["expiryDate"].disable();
    }
  }
}
