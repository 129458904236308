import { NxMessageToastService } from '@aposin/ng-aquila/message';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { UserType } from '../core/userType.model';
import { MESSAGE_CONSTS } from '../ValidationMessages';

@Component({
  selector: 'app-workshop-edit',
  templateUrl: './workshop-edit.component.html',
  styleUrls: ['./workshop-edit.component.css']
})
export class WorkshopEditComponent implements OnInit {
  private _apiUrl: string;
  Messages: any;
  clientId: number;
  workShopForm: FormGroup;
  zoneList: any;
  cityList: any;
  asmUsers: any;
  stateList: any;
  spinner: boolean;
  login: any;
  userId: any;
  checkUploadPath: any;
  response: any;
  panUploadPath: any;
  gstUploadPath: any;
  accpetedTypes:string ="application/pdf";
  encryptedId : string;
  userbaseTypeId: number;
  @Output() closeModal = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private messageToastService: NxMessageToastService,
    private appConfig : AppConfig, private customErrorHandler : CustomErrorHandler) { 
        
      this._apiUrl = appConfig.baseUrl;
      this.formInit();
      this.Messages = MESSAGE_CONSTS;
      this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
      this.userId = localStorage.getItem("IDENTIFIER");
      this.userbaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
  }

  ngOnInit(): void {
    this.getZones();
    this.getStateDetails();
    this.getWorkshopDetails();
  }


  get f() {
    return this.workShopForm.controls;
  }

  formInit() {
    this.workShopForm = new FormBuilder().group({
      workshopId : new FormControl("", [Validators.required]),
      userType: [UserType.WorkShop],
      dealerCode: new FormControl( '',  [Validators.required]),
      workshopCode: new FormControl('',  [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      workshopName: ["", Validators.required],
      workshopAddress: ["", Validators.required],
      serviceTaxNumber: [""],
      panNo: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.PAN_NUMBER_REGEX),
      ]),
      gstNumber: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.GSTNO_REGEX),
      ]),
      contactPerson: ["", Validators.required],
      contactNumber: new FormControl("", [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ]),
      primaryEmailIdContactPerson: new FormControl( '', [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.EMAIL_REGEX),
      ]),
      contactPersonEmailId: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.EMAIL_REGEX),
      ]),
      bankAccountNo: new FormControl("", [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.NUMBER_REGEX),
      ]),
      bankName: ["", Validators.required],
      ifscCode: ["", Validators.required],
      beneficiaryName: ["", Validators.required],
      bankBranchAddress: ["", Validators.required],
      isActive: [-1],
      firstlogin: [1],
      CancelChequeImage: new FormControl(""),
      PanCardImage: new FormControl(""),
      GstCertificateImage: new FormControl(""),
      zoneId: ["", Validators.required],
      clientId: this.clientId,
      superUserId: ["", Validators.required],
      stateId: ["", Validators.required],
      cityId: [{value: '', disabled: true}, Validators.required],
      pincode: ["", [Validators.required, Validators.pattern(MESSAGE_CONSTS.PINCODE_REGEX)]]
    });
   
  }

  getWorkshopDetails() {
    this.authService.get(this._apiUrl + `/api/v2/Workshops/GetworkshopListByid/null`).subscribe(response => {
      if (response) {
        this.encryptedId = response.encryptedId;
        this.updateWorkshopDetails(response);
      }
    }, (error) => {
      this.customErrorHandler.handleError(error);
    });
  }

  updateWorkshopDetails(data: any) {
    this.workShopForm.patchValue({
      workshopId : data.workshopId,
      userType : data.userType,
      dealerCode : data.dealerCode,
      workshopCode : data.workshopCode,
      workshopName : data.workshopName,
      workshopAddress : data.workshopAddress,
      serviceTaxNumber : data.serviceTaxNumber,
      panNo : data.panNo,
      gstNumber: data.gstNumber,
      contactPerson : data.contactPerson,
      contactNumber : data.contactNumber,
      primaryEmailIdContactPerson : data.primaryEmailIdContactPerson,
      contactPersonEmailId : data.contactPersonEmailId,
      bankAccountNo : data.bankAccountNo,
      bankName : data.bankName,
      ifscCode : data.ifscCode,
      beneficiaryName: data.beneficiaryName,
      bankBranchAddress: data.bankBranchAddress,
      isActive: data.isActive,
      firstlogin: data.firstlogin,
      zoneId: data.zoneId,
      clientId: data.clientId,
      superUserId: data.superUserId,
      stateId: data.stateId,
      cityId: data.cityId,
      pincode: data.pincode,
      cancelChequeImage: data.cancelChequeImage,
      panCardImage: data.panCardImage,
      gstCertificateImage: data.gstCertificateImage,
      minWalletBalance: data.minWalletBalance
    });
    if(this.userbaseTypeId == UserType.Dealer){
      this.login = 'dealer';
      this.workShopForm.get('userType').setValue(UserType.Dealer)
      this.workShopForm.get('workshopCode').clearValidators();
      this.workShopForm.get('workshopCode').setValue(null);
    }
    else {
      this.login = 'workshop';
      
      if (this.userId)
        this.getDealerByCode();
    }

    this.onzoneChanges(data.zoneId);
    this.getStateWiseCityList(data.stateId);
    this.workShopForm.updateValueAndValidity();
  }

  getDealerByCode() {
    this.authService
      .get(this._apiUrl + `/api/v1/Workshops/GetDealerDetailsbyCode`)
      .subscribe(
        (response) => {
          if (response.success) {
            this.workShopForm.patchValue({
              dealerCode: response.data[0].dealerCode,
              zoneId: response.data[0].zoneId,
            });
            this.onzoneChanges(response.data[0].zoneId)
          }
        },
        (error) => {
          this.customErrorHandler.handleError(error);
        }
      );
  }


  getZones() {
   this.authService.get(this._apiUrl + `/api/v1/Zones/client/${this.clientId}?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          this.customErrorHandler.handleError(error);
        }
      );
  }

  getStateDetails() {
    this.authService
      .get(this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
          }
        },
        (error) => {
          this.customErrorHandler.handleError(error);
        }
      );
  }

  getStateWiseCityList(stateId) {
    if(stateId!=0)
    {
      this.authService.get(this._apiUrl + `/api/v1/Cities/state/${stateId}`).subscribe(response => {
        if (response) {
          this.cityList = response;
          if(!this.cityList.find(x=>x.id ==this.workShopForm.get("cityId").value)){
            this.workShopForm.patchValue({
              cityId:null
            })
          }
        this.workShopForm.controls.cityId.enable();
        }
      }, (error) => {
        this.workShopForm.controls.cityId.disable();
        this.customErrorHandler.handleError(error);
      });
    }
  }

  getCityList() {
    this.authService.get(this._apiUrl + '/api/v1/Cities' + "?pageNumber=1&pageSize=50").subscribe(response => {
      if (response) {
        this.cityList = response;
      }
    }, (error) => {
      this.customErrorHandler.handleError(error);
    });
  }

  onzoneChanges(id: any) {
    this.authService
      .get(this._apiUrl + `/api/v1/Users/GetUserDetailsfromZoneId/${id}/${this.clientId}?userBaseType=${UserType.BD}`)
      .subscribe(
        (response) => {
          if (response) {
            this.asmUsers = response;
          }
        },
        (error) => {
          this.customErrorHandler.handleError(error);
        }
      );
  }

  public checkUploadFinished = (event) => {
    this.response = event;
    this.workShopForm.get('CancelChequeImage').setValue(this.response.dbPath);
    this.workShopForm.updateValueAndValidity();
    //this.checkUploadPath = this.response.dbPath;
  };

  public panUploadFinished = (event) => {
    this.response = event;
    this.workShopForm.get('PanCardImage').setValue(this.response.dbPath);
    this.workShopForm.updateValueAndValidity();
    //this.panUploadPath = this.response.dbPath;
  };

  public gstUploadFinished = (event) => {
    this.response = event;
    this.workShopForm.get('GstCertificateImage').setValue(this.response.dbPath);
    this.workShopForm.updateValueAndValidity();
    //this.gstUploadPath = this.response.dbPath;
  };

  onSubmit() {
    this.authService.put(this._apiUrl + `/api/v1/Workshops/update/${this.encryptedId}`, this.workShopForm.value)
    .subscribe(response => {
      if(response.success)
        {
          this.messageToastService.open('Good Job!!! '+response.responsemsg, {
            context: "success",
            duration: 5000,
          });
        this.closeModal.emit(false);
        }
        else
        {
          this.messageToastService.open('Sorry!!!'+response.responsemsg, {
            context: "info",
            duration: 5000,
          });
        }
      
    }, (error) => {
      this.customErrorHandler.handleError(error);
    });
  }  

  logout()
  {
    this.authService.Logout(this.userId);
  }
}
