import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { PolicyStatus } from '../core/policyStatus.model';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-policy-sale-detailed-report',
  templateUrl: './policy-sale-detailed-report.component.html',
  styleUrls: ['./policy-sale-detailed-report.component.css']
})
export class PolicySaleDetailedReportComponent implements OnInit {

  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;
  @Input() policyNo : string;
  @Input() policyType: Array<number>;
  @Input() policyStatus: typeof PolicyStatus;
  @Input() VehicleAge:number;
  @Input() model:string;
  reportSearchForm: FormGroup;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    
  }
  ngOnInit(): void {
    this.searchReportFormBuilder();
  }

  searchReportFormBuilder() {
    this.reportSearchForm = new FormGroup({
      startDatepicker: new FormControl(null),
      endDatepicker: new FormControl(null),
      policyNo: new FormControl(null),
      policyType: new FormControl(new Array<number>()),
      VehicleAge: new FormControl(-1),
      model: new FormControl(null),
      ClientId: new FormControl(this.clientId),
      WorkshopId: new FormControl(null),
    });
  }

  loadReport() {
    this.pageNumber =1;
    this.getReportData();
  }

  getReportData() {
    this.searchReportFormBuilder();
    this.updateReportSearchForm();
    
    this.authService.PostList(this._apiUrl + `/api/v1/Report/GetPolicySalesReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, this.reportSearchForm.value)
      .subscribe(response => {
        if (response) {
         
          this.ReportData = response.body;
          var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
          this.count = pagingProp.TotalCount;
        }
      }, (error) => {
        this.errorHandler.handleError(error);
      });
  }

  updateReportSearchForm() {
    if(this.policyStartDate) this.reportSearchForm.patchValue({ "startDatepicker": this.policyStartDate.format('LL') });
    if(this.policyStartDate) this.reportSearchForm.patchValue({ "startDatepicker": this.policyStartDate.format('LL') });
    if(this.policyEndDate) this.reportSearchForm.patchValue({ "endDatepicker": this.policyEndDate.format('LL') });
    if(this.policyNo) this.reportSearchForm.patchValue({ "policyNo": this.policyNo });
    if(this.policyType) this.reportSearchForm.patchValue({ "policyType": this.policyType });
    if(this.VehicleAge) this.reportSearchForm.patchValue({ "VehicleAge": this.VehicleAge }) ;
    if(this.model) this.reportSearchForm.patchValue({ "model": this.model });
  }

  exporttoexcel(){
    this.pageNumber = -1;
    this.getExportData();
  }

  getExportData() {
    this.searchReportFormBuilder();
    this.updateReportSearchForm();

    this.authService.PostList(this._apiUrl + `/api/v1/Report/GetPolicySalesReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, this.reportSearchForm.value)
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach((element) => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.dealerCode = item.dealerCode;
    data.dealerName = item.name;
    data.dealerState = item.dealerState;
    data.dealerCity = item.dealerCity;
    data.dealerManager = item.asmName;
    data.invoiceNo = item.policyDetails.invoiceNo;
    data.vin = item.policyDetails.vinChassisNo;
    data.licencePlateNo = item.policyDetails.licensePlateNo;
    data.model = item.policyDetails.model;
    data.policyName = item.policyDetails.policyName;
    data.effectiveDate = new Date(item.policyDetails.effectiveDate).toLocaleDateString();
    data.expiryDate = new Date(item.policyDetails.expiryDate).toLocaleDateString();
    data.price = item.policyDetails.unitPrice;
    data.zone = item.zonename;
    data.custName = item.policyDetails.customerFirstName +' ' +item.policyDetails.customerLastName;
    data.custMobile = item.policyDetails.customerMobileNo;
    data.custEmail = item.policyDetails.customerEmail;
    data.invoiceDate = new Date(item.policyDetails.invoiceDate).toLocaleDateString();
    
    if (item.policyDetails.policyStatusId == PolicyStatus.Active) {
      data.policyStatus = "Active";
    } else if (item.policyDetails.policyStatusId == PolicyStatus.Cancel) {
      data.policyStatus = "Cancel";
    } else if (item.policyDetails.policyStatusId == PolicyStatus.Modified) {
      data.policyStatus = "Modified";
    } else if (item.policyDetails.policyStatusId == PolicyStatus.CancelationApprovalPending) {
      data.policyStatus = "Cancelation Approval Pending";
    } else if (
      item.policyStatusId == PolicyStatus.ModificationApprovalPending
    ) {
      data.policyStatus = "Modification Approval Pending";
    }
    data.sellingAdvisor = item.policyDetails.soldBy;
    this.exportdata.push(data);
  }

  bindexcel(){
    /* json data is passed over here */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    ws.A1.v = "Dealer/Workshop Code";
    ws.B1.v = "Dealer/Workshop Name";
    ws.C1.v = "Dealer/Workshop State";
    ws.D1.v = "Dealer/Workshop City";
    ws.E1.v = "Dealer Manager";
    ws.F1.v = "Invoice No";
    ws.G1.v = "Vin Chasis No";
    ws.H1.v = "License Plate No";

    ws.I1.v = "Model";
    ws.J1.v = "Policy Name";
    ws.K1.v = "Effective Date";
    ws.L1.v = "Expiry Date";
    ws.M1.v = "Price";
    ws.N1.v = "Zone";

    ws.O1.v = "Customer Name";
    ws.P1.v = "Customer Mobile";
    ws.Q1.v = "Customer Email";
    ws.R1.v = "Invoice Date";
    ws.S1.v = "Policy Status";
    ws.T1.v = "Selling Advisor";

    XLSX.utils.book_append_sheet(wb, ws, "Policy_Detailed_Sale_Report");
    /* save to file */
    this.fileName =
      "PolicyDetailedSaleReport_" +
      new Date(Date.now()).toLocaleString().split(",")[0] +
      ".xlsx";
    XLSX.writeFile(wb, this.fileName);
  }

}
