<div nxLayout='grid'>
  <div nxRow>
    <div nxCol="12" style="overflow-x:hidden">
      <div nxRow nxRowJustify="start,around">
        <div nxCol="12" class="walletheaderillustration"></div>
        <h1 nxHeadline="page">Wallet Payment</h1>
      </div>
      <div nxRow nxRowJustify="start,around">
        <nx-message-banner context="warning" *ngIf="!closed" closable="true" (close)="closed = true">
         The feild marked with asterisk(*) are required.
        </nx-message-banner>
      </div>
      <form [formGroup]="walletRequestForm">
        <div nxRow>
          <div nxCol="12"  style="margin-top: 5%;">
            <nx-formfield nxLabel="Requested Amount">
              <nx-formfield-label class="required"> Recharge Amount </nx-formfield-label>
              <input nxInput formControlName="requestedAmount" type="number" />
              <nx-error nxFormfieldError>
                <div *ngIf="RequestFormControls.requestedAmount.errors?.required"><strong>Please note:</strong>Enter Request Amount.</div>
                <div *ngIf="RequestFormControls.requestedAmount.errors?.pattern"><strong>Please note:</strong>Accept numbers only.</div>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" style="margin-top: 5%;">
            <nx-formfield nxLabel="Mobile Number">
              <nx-formfield-label class="required">Mobile Number</nx-formfield-label>
              <input nxInput formControlName="mobileNo" type="text" />
              <nx-error nxFormfieldError>
                <div *ngIf="RequestFormControls.mobileNo.errors?.required"><strong>Please note: </strong>Enter Mobile number.</div>
                <div *ngIf="RequestFormControls.mobileNo.errors?.pattern"><strong>Please note: </strong>Enter  Valid Mobile number.</div>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" class="nx-margin-top-2m" style="text-align: center;">
            <button nxButton="primary" type="button" (click)="PayNow()"
              [disabled]="walletRequestForm.invalid">Proceed to Pay</button>
            <button nxButton="emphasis" type="button" (click)="onModalClose(false)"  >Cancel</button>   
          </div>
        </div>
      </form>
    </div>
    </div>
  </div>

  <!-- Response Status -->
  <ng-template #template>
    <div nxLayout='grid'  style="margin-top:2%;">
      <div nxRow nxRowJustify="start,around">
        <h3 class="u-text-align-center" nxHeadline="subsection-small">{{transactionStatus | titlecase}}</h3>
      </div>
      <div nxRow>
        <div nxCol="12" [ngClass]="(!isFailed)?'paymentfailed':'paymentsuccess'" style="margin-top: 5%; margin-left: 15%;">
        </div>
      </div>
      <div nxRow>
        <div nxCol="12" style="margin-top: 5%; text-align: center;">
          <nx-label>Transaction No</nx-label>
          <nx-label>{{RequestData.txnid}}</nx-label>
        </div>
      </div>
      <div nxRow>
        <div nxCol="12" style="margin-top: 5%; text-align: center;">
          <nx-label>Transaction Amount</nx-label>
          <nx-label>{{RequestData.amount}}</nx-label>
        </div>
      </div>
      <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="12">
          <button (click)="onClose()" nxButton="block primary">
            close
          </button>
        </div>
      </div>
    </div>  
  </ng-template>
