<footer nx-footer class="customfooter">
  <nx-footer-copyright>
    &copy; Allianz Partners {{ year }}. All Rights Reserved.
  </nx-footer-copyright>
  <nx-footer-navigation>
    <nx-footer-link>
      <a
        href="https://www.allianz-partners.com/en_IN/legal-information.html"
        target="_blank"
        >Legal Information</a
      >
    </nx-footer-link>
    <nx-footer-link>
      <a
        href="https://www.allianz-partners.com/en_IN/privacy-statement.html"
        target="_blank"
        >Privacy Statement</a
      >
    </nx-footer-link>
    <!-- <nx-footer-link>
      <a href="https://www.allianz-partners.com/en_IN/legal-information.html" target="_blank">Legal Information</a>
    </nx-footer-link> -->
  </nx-footer-navigation>
</footer>
