<header nx-header>
  <nx-header-row>
    <nx-header-navigation>
      <nx-header-navigation-item
        *ngFor="let item of menuItemList | isActiveCheck"
      >
        <a
          [routerLink]="item.route"
          routerLinkActive="is-active"
          nxHeaderLink
          >{{ item.displayName }}</a
        >
      </nx-header-navigation-item>

      <nx-header-navigation-item *ngIf="userRole == 'Admin'">
      </nx-header-navigation-item>
      <!-- <nx-header-navigation-item *ngIf="userRole=='Dealer'">
        <a routerLinkActive="is-active" target="_blank" nxHeaderLink [routerLink]="['/registration']"
          [queryParams]="{ login: 'workshop'}">
          WorkShop Registration
        </a>
      </nx-header-navigation-item> -->
    </nx-header-navigation>
  </nx-header-row>
</header>
<app-changepassword></app-changepassword>
