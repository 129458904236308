<nx-card style="display:block !important;margin:45px;padding:0px !important">
    <nx-card-header>
      <nx-small-stage class="nx-margin-bottom-m">
        <nx-small-stage-image nxSmallStageImageEnd src="/assets/illustrations/woman_looking_at_certificates_on_wall.png"></nx-small-stage-image>
        <h2 nxHeadline="subsection" class="nx-font-weight-regular">
            Plan Details
        </h2>
      </nx-small-stage>
    </nx-card-header>

    <div nxLayout="grid" class="docs-grid-colored-grid card-content">
        <form [formGroup]="planDetailForm">
            <div nxRow nxRowAlignItems="center,center,center,center">
                <div nxCol="12,12,4,2" >
                    <figure nxFigure="1by1">
                    <img src="/assets/illustrations/illustration_service_plan.png" alt="Image Not Found"/>
                    </figure>
                </div>

                <div nxCol="12,12,4,2" style="text-align: -webkit-center;">
                    <button class="navigation" nxIconButton="tertiary" aria-label="prev page" type="button" (click)="PrevPage()" [routerLink]="['/sellpolicy/customerdetails']">
                        <ul class="iconlist iconlist__item ">
                            <nx-icon size="l" name="chevron-left-circle-o" ></nx-icon><span style="font-size: small;">Prev</span>
                        </ul>
                    </button>
                </div>

                <div  nxCol="12,12,6">
                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Plan Type">
                                <nx-formfield-label class="required">Plan Type</nx-formfield-label>
                                <nx-dropdown formControlName="planId" (selectionChange)="onPlanChanges($event.value)">
                                    <nx-dropdown-item *ngFor="let item of planMasterList | isActiveCheck"
                                        [nxValue]="item.planId">{{item.planName}}</nx-dropdown-item>
                                </nx-dropdown>
                                <nx-error nxFormfieldError>
                                    Please select an option.
                                </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Policy Term (Years)">
                                <input nxInput value="{{PolicyTerm}}" readonly />
                            </nx-formfield>                        
                        </div>
                    </div>

                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Effective Date">
                                <nx-formfield-label class="required">Effective Date</nx-formfield-label>
                                <input
                                    nxDatefield
                                    nxInput
                                    [nxMin]="effectiveDate"
                                    [nxDatepicker]="effectiveDatepicker"
                                    formControlName="planeffectiveDate"
                                    (dateChange)="onEffectiveDateChange()"/>
                                        <nx-datepicker-toggle
                                        [for]="effectiveDatepicker"
                                        nxFormfieldSuffix>
                                        </nx-datepicker-toggle>
                                        <nx-datepicker #effectiveDatepicker></nx-datepicker>
                                        <nx-error
                                        nxFormfieldError
                                        *ngIf="f.planeffectiveDate.errors?.required">
                                        <strong>Please note:</strong> this field is required!
                                        </nx-error>
                                        <nx-error
                                        nxFormfieldError
                                        *ngIf="f.planeffectiveDate.errors?.nxDatefieldMin">
                                        <strong>Please note:</strong> Effective Date cannot be less than {{effectiveDate | date:'MM/dd/yyyy'}} !
                                        </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Expiry Date">
                                <input
                                    nxDatefield
                                    nxInput
                                    [nxDatepicker]="expiryDatepicker"
                                    formControlName="planexpiryDate"
                                    readonly/>
                                    <nx-datepicker-toggle
                                    [for]="expiryDatepicker"
                                    nxFormfieldSuffix
                                    >
                                    </nx-datepicker-toggle>
                                    <nx-datepicker #expiryDatepicker></nx-datepicker>
                                    <nx-error
                                    nxFormfieldError
                                    *ngIf="f.planexpiryDate.errors?.required">
                                    <strong>Please note:</strong> this field is required!
                                    </nx-error>
                            </nx-formfield>                        
                        </div>
                    </div>

                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Price">
                                <input nxInput formControlName="price" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="GST Price">
                                <input nxInput formControlName="gstPrice" readonly />
                            </nx-formfield>
                            
                        </div>
                    </div>
                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,start" *ngIf="IsCess">
                        <div nxCol="12,12,6">
                            <h3 nxHeadline="subsection-xsmall" style="color: green; margin-bottom: 0px !important;">Applicable Cess/Tax(es)</h3>
                        </div>
                        <div nxCol="12,12,6" *ngFor="let item of cessList">
                            <nx-label size="small">{{item.cessName}} : {{item.cessPercentage}} %</nx-label>
                        </div>
                    </div>                    
                </div>

                <div nxCol="12,12,4,2" style="text-align: -webkit-center;">
                    <button class="navigation" nxIconButton="tertiary" aria-label="next page" type="button" [disabled]="planDetailForm.invalid" (click)="savePlanDetails()">
                        <ul class="iconlist iconlist__item">
                            <nx-icon size="l" name="chevron-right-circle-o"></nx-icon><span style="font-size: small;">Next</span>
                        </ul>
                    </button>
                </div>
            </div>
        </form>
    </div>


    <nx-card-footer>
        <nx-link>
          <a >
            <nx-icon name='play'></nx-icon>
            Step 3/5
          </a>
        </nx-link>
    </nx-card-footer>
</nx-card>
