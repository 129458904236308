<app-header></app-header>
<app-menu></app-menu>
<div nxLayout='grid' style="margin-top:2%;">
    <form [formGroup]="workShopForm">
        <div nxRow class="disabledbutton">
            <div nxCol="12"  class="nx-margin-bottom-s" nxColOffset="2">
                <nx-link>
                  <a >
                    <nx-icon name='user-o'></nx-icon>
                    <h3 nxHeadline="section" class="nx-margin-bottom-0">Personal Details</h3>
                  </a>
                </nx-link>
              </div>
            <div nxCol="4,4" nxColOffset="2" >
                <nx-formfield nxLabel="Dealer Code">
                    <input nxInput  formControlName="dealerCode" />
                </nx-formfield>

            </div>
            <div nxCol="4,4" *ngIf="!isDealer">
                <nx-formfield nxLabel="Workshop code" >
                    <input nxInput  formControlName="workshopCode" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2" *ngIf="!isDealer">
                <nx-formfield nxLabel="Workshop Name" >
                    <input nxInput  formControlName="workshopName" />
                </nx-formfield>
                
            </div>
            <div nxCol="4,4" >
                <nx-formfield nxLabel="Dealer Name" *ngIf="isDealer">
                    <input nxInput  formControlName="dealerName" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="Area Sales Manager">
                    <nx-dropdown formControlName="superUserId">
                      <nx-dropdown-item *ngFor="let item of asmUsers" [nxValue]="item.id">{{item.name}}
                      </nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
            </div>
            <div nxCol="4,4" >
                <nx-formfield nxLabel="Dealer Address" *ngIf="isDealer">
                    <input nxInput  formControlName="dealerAddress" />
                </nx-formfield>
                <nx-formfield nxLabel="Workshop Address" *ngIf="!isDealer">
                    <input nxInput  formControlName="dealerAddress" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="Service Tax Number">
                    <input nxInput b formControlName="serviceTaxNumber" />
                </nx-formfield>
            </div>
            <div nxCol="4,4">
                <nx-formfield nxLabel="PAN Number">
                    <input nxInput  formControlName="panNo" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="Contact Person">
                    <input nxInput  formControlName="contactPerson" />
                </nx-formfield>
            </div>
            <div nxCol="4,4">
                <nx-formfield nxLabel="Contact Number">
                    <input nxInput  formControlName="contactNumber" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="Primary Email">
                    <input nxInput  formControlName="primaryEmailIdContactPerson" />
                </nx-formfield>
            </div>
            <div nxCol="4,4">
                <nx-formfield nxLabel="Secondary Email">
                    <input nxInput  formControlName="contactPersonEmailId" />
                </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="Region">
                    <nx-dropdown formControlName="zone">
                      <nx-dropdown-item *ngFor="let item of zoneList" [nxValue]="item.id">{{item.zone}}
                      </nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
            </div>
            <div nxCol="4,4" >
                <nx-formfield nxLabel="State">
                    <nx-dropdown formControlName="stateId">
                      <nx-dropdown-item *ngFor="let item of stateList" [nxValue]="item.id">{{item.stateName}}
                      </nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
                <nx-formfield nxLabel="City">
                    <nx-dropdown formControlName="cityId">
                        <nx-dropdown-item *ngFor="let item of cityList" [nxValue]="item.id">
                            {{item.cityName}}
                        </nx-dropdown-item>
                    </nx-dropdown>
                </nx-formfield>
            </div>
            <!-- Bank details are kept hidden as per request from bussiness -->
            <div *ngIf="false">
                <div nxCol="12" class="nx-margin-bottom-s" nxColOffset="2">
                    <nx-link>
                    <a>
                        <nx-icon name='product-bank-monument-info'></nx-icon>
                        <h3 nxHeadline="section" class="nx-margin-bottom-0">Bank Details</h3>
                    </a>
                    </nx-link>
                </div>
                <div nxCol="4,4" nxColOffset="2">
                    <nx-formfield nxLabel="Bank Account Number">
                        <input nxInput  formControlName="bankAccountNo" />
                    </nx-formfield>
                </div>
                <div nxCol="4,4" >
                    <nx-formfield nxLabel="Bank Name" >
                        <input nxInput  formControlName="bankName" />
                    </nx-formfield>
                </div>
                <div nxCol="4,4" nxColOffset="2">
                    <nx-formfield nxLabel="IFSC Code">
                        <input nxInput  formControlName="ifscCode" />
                    </nx-formfield>
                </div>
                <div nxCol="4,4" >
                    <nx-formfield nxLabel="Beneficiary Name">
                        <input nxInput  formControlName="beneficiaryName" />
                    </nx-formfield>
                </div>
                <div nxCol="4,4" nxColOffset="2">
                    <nx-formfield nxLabel="Bank Branch Address">
                        <input nxInput  formControlName="bankBranchAddress" />
                    </nx-formfield>
                </div>
                <div nxCol="4"  *ngIf="cancelChequePath" >
                    <span style="font-size: 15px !important;font-weight: 400px !important;">Cancelled Cheque</span>
                    <br />
                    <br />
                    <div *ngIf="showPanDownlaodBtn">
                        <nx-icon name="pdf" size="m" style="color: red;"></nx-icon>
                        <span style="vertical-align: top;" class="enabledbutton"><a href="{{cancelChequePath}}" target="_blank">{{chequefilename}}</a></span>
                    </div>
                    <div *ngIf="!showPanDownlaodBtn">
                        <span style="vertical-align: top;">No attachment found</span>
                    </div>
                </div>
                <div nxCol="4" *ngIf="panCardPath" nxColOffset="2">
                    <span style="font-size: 15px !important;font-weight: 400px !important;">Pan Card</span>
                    <br />
                    <br />
                    <div *ngIf="showPanDownlaodBtn">
                        <nx-icon name="pdf" size="m" style="color: red;"></nx-icon>
                        <span style="vertical-align: top;" class="enabledbutton"><a href="{{panCardPath}}" target="_blank">{{panfilename}}</a></span>
                    </div>
                    <div *ngIf="!showPanDownlaodBtn">
                        <span style="vertical-align: top;">No attachment found</span>
                    </div>
                </div>
                <div nxCol="4"  *ngIf="gstCardPath">
                    <span style="font-size: 15px !important;font-weight: 400px !important;">GST Certificate</span>
                    <br />
                    <br />
                    <div *ngIf="showGstDownlaodBtn">
                        <nx-icon name="pdf" size="m" style="color: red;"></nx-icon>
                        <span style="vertical-align: top;" class="enabledbutton"><a href="{{gstCardPath}}" target="_blank">{{gstfilename}}</a></span>
                    </div>
                    <div *ngIf="!showGstDownlaodBtn">
                        <span style="vertical-align: top;">No attachment found</span>
                    </div>
                </div>
            </div>
        </div>
        <div nxRow style="margin-top: 2%;">
            <div nxCol="10" class="nx-margin-bottom-m" nxColOffset="2">
                <button nxButton="tertiary" type="button"> 
                    <a routerLink='/dashboard'>GO TO DASHBOARD <nx-icon name='chevron-right-circle-o' class="nx-margin-left-s"></nx-icon></a></button>
          </div>
          </div>
    </form>
</div>
<app-footer></app-footer>