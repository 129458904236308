import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from '../core/userType.model';

@Pipe({
  name: 'filterUserRoleDD'
})
export class nsmUserFilterPipe implements PipeTransform {
  BaseType: number = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));

  transform(value: any): any {
    switch (this.BaseType) {
      case UserType.SysAdmin: {
        return value;
      }
      case UserType.Admin: {
        let result = value.length != 0 ? value.filter(t => (t.baseType == UserType.RSM || t.baseType == UserType.BD || t.baseType == UserType.NSM  )) : null;
        return result;
      }
      case UserType.AzPAdmin: {
        let result = value.length != 0 ? value.filter(t => (t.baseType == UserType.RSM || t.baseType == UserType.BD || t.baseType == UserType.NSM || t.baseType == UserType.Admin || t.baseType == UserType.AzPAdmin)) : null;
        return result;
      }
      case UserType.NSM: {
        let result = value.length != 0 ? value.filter(t => (t.baseType == UserType.RSM || t.baseType == UserType.BD)) : null;
        return result;
      }
      case UserType.RSM: {
        let result = value.length != 0 ? value.filter(t => (t.baseType == UserType.BD)) : null;
        return result;
      }
      case UserType.BD: {
        let result = value.length != 0 ? value.filter(t => (t.baseType == UserType.BD)) : null;
        return result;
      }
      default: {
        return value;
      }
    }
  }

}
