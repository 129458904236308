<app-header></app-header>
<app-menu></app-menu>


<div nxLayout="grid" class="nx-margin-top-2m">

     <div nxRow class="nx-margin-left-5xl nx-margin-right-4m">
        <div nxCol="2" >
            <nx-formfield nxLabel="E- Invoice Status">
                <nx-dropdown #statusDropdown [(nxValue)]="invoiceStatus" (selectionChange)="onStatusChanges()">
                  <nx-dropdown-item nxValue="Pending"></nx-dropdown-item>
                  <nx-dropdown-item nxValue="Completed"></nx-dropdown-item>
                </nx-dropdown>
            </nx-formfield>
        </div>
    </div>

    <div nxRow class="nx-margin-left-5xl nx-margin-right-4m">
        <div nxCol="10" nxColOffset="1" >
            <table nxTable zebra condensed nxSort (sortChange)="sortTable($event)">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="policyNo">
                            Policy Number
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="policyName">
                            Policy Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerFirstName">
                            Customer Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerMobileNo">
                            Mobile Number
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerEmail">
                            Email
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="paymentDate">
                            Created Date
                        </th>
                       
                        <th nxHeaderCell>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr nxTableRow *ngFor="let item of policyList">
                        <td nxTableCell>{{item.policyNo}}</td>
                        <td nxTableCell>{{item.policyName}}</td>
                        <td nxTableCell>{{item.customerFirstName}} {{item.customerLastName}}</td>
                        <td nxTableCell>{{item.customerMobileNo}}</td>
                        <td nxTableCell>{{item.customerEmail}}</td>
                        <td nxTableCell>{{item.paymentDate | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell>
                            <nx-context-menu #menu="nxContextMenu">
                                <ng-template nxContextMenuContent>
                                    <!-- <button (click)="download($event,item.policyNo)"nxContextMenuItem type="button">Download Upload-Format</button> -->
                                    <button (click)="upload($event,item.policyNo)"nxContextMenuItem type="button">Upload E-Invoice</button>
                                </ng-template>
                            </nx-context-menu>
                            <button nxIconButton="tertiary medium" type="button" [nxContextMenuTriggerFor]="menu"
                                aria-label="Open menu" [nxTooltipPosition]="'top'" [nxTooltip]="'Please click here'">
                                <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div nxRow class="nx-margin-left-5xl nx-margin-right-4m" style="float: right;">
                <div nxCol="6">
                    <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced" (nxGoPrev)="prevPage()"
                      (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
                    </nx-pagination>
                  </div>
            </div>
        </div>
        <div nxCol="12,10" class="nx-margin-top-2xl" *ngIf="count==0">
            <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
          </div>
    </div>
</div>
<app-footer></app-footer>


<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="UploadInvoiceModal"
  [nxShowCloseIcon]="false">
  <div nxLayout="grid">
    <div nxRow>
        <div nxCol="12" >
            <nx-label><h3>Upload E-Invoice (*.pdf files only) </h3></nx-label>
          </div>
      <div nxCol="12" >
        <app-upload (onUploadFinished)="checkUploadFinished($event)" [acceptedfiles]="accpetedTypes" [uploadPath]="uploadPath"></app-upload>
      </div>
    </div>
    <div nxRow>
        <div nxCol="12">
            <button (click)="download()" nxButton="block tertiary" type="button">
                <nx-icon size="l" name="xls" class="nx-margin-right-2xs"></nx-icon>Click here to download customer details
            </button>
        </div>
        <div nxCol="12" >
            <button (click)="modalClose()" nxButton="block secondary"> Close </button>
        </div>
      </div>
  </div>

</nx-modal>
