import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { PolicyStatus } from "../core/policyStatus.model";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import * as moment from "moment";
import { SortEvent, SortDirection } from "@aposin/ng-aquila/table";
import { SortingService } from "../sorting.service";
import { pipe } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import { CustomErrorHandler } from "../core/custom-error-handler";

@Component({
  selector: "app-policylist",
  templateUrl: "./policylist.component.html",
  styleUrls: ["./policylist.component.css"],
})
export class PolicylistComponent implements OnInit {
  _apiUrl: string;
  clientId: number;
  policyList: any = [];
  policyEditModalOpen: boolean = false;
  id: number;
  transactionId : string;
  policyStatus: any;
  confirmModalOpen: boolean = false;
  policyStatusDetails: any = {};
  policyDetails: any = {};
  sellPolicyData: sellPolicyData = new sellPolicyData();
  userPermission: any = {};
  _edit: boolean = false;
  policyStartDate: moment.Moment;
  policyEndDate: moment.Moment;
  userTypeId: number;
  userId: number;
  policyNumber: string = "";
  private _eInvoiceStatus: boolean = false;

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  policyPaymentDate: any;
  prevPage() {
    this.pageNumber--;
    this.getPolicyList();
  }

  nextPage() {
    this.pageNumber++;
    this.getPolicyList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getPolicyList();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private messageToastService: NxMessageToastService,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.policyStatus = PolicyStatus;
  }

  ngOnInit() {
    this.getPolicyList();
    this.setUserPermission();
  }
  /** sorting starts here */

  sortTable(sort: SortEvent) {
    //Sorts the table data by a certain category.
    this.policyList = this.policyList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */

  setUserPermission() {
    this.authService.userPermissions().subscribe((response) => {
      if (response) {
        this.userPermission = response;
      }
    });
  }
  //get all policies
  getPolicyList() {
    var startdate =
      this.policyStartDate == undefined
        ? ""
        : this.policyStartDate.format("LL");
    var enddate =
      this.policyEndDate == undefined ? "" : this.policyEndDate.format("LL");
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/PolicyDetails" +
          "?policyNo=" +
          this.policyNumber +
          "&pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm +
          "&startDatepicker=" +
          startdate +
          "&endDatepicker=" +
          enddate
      )
      .subscribe(
        (response) => {
          if (response) {
            this.policyList = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {}
      );
  }

  download($event, policyNo) {
    this.authService
      .export(this._apiUrl + "/api/v1/DownLoadPage/policyNo/" + policyNo)
      .subscribe((data) => saveAs(data, policyNo + `_report.pdf`));
  }

  toggleModal($event, id,transactionId) {
    //this.getPolicyDetails(id);
    this.id = id;
    this.transactionId = transactionId;
    this.policyEditModalOpen = !this.policyEditModalOpen;
    if (!this.policyEditModalOpen) {
      this.getPolicyList();
    }
  }

  confirmModal(policyDetails) {
    this.policyPaymentDate = policyDetails.paymentDate;
    this.policyDetails = policyDetails;
    this.policyDetails = {
      id: policyDetails.id,
      transactionId:policyDetails.transactionId,
      policyNo: policyDetails.policyNo,
      customerFirstName: policyDetails.customerFirstName,
      customerMiddleName: policyDetails.customerMiddleName,
      customerLastName: policyDetails.customerLastName,
      customerPhoneNo: policyDetails.customerPhoneNo,
      custGstNumber: policyDetails.custGstNumber,
      customerMobileNo: policyDetails.customerMobileNo,
      customerEmail: policyDetails.customerEmail,
      customerCompany: policyDetails.customerCompany,
      stateId: policyDetails.stateId,
      cityId: policyDetails.cityId,
      workshopId: policyDetails.workshopId,
      clientId: this.clientId,
      status: this.policyStatus.CancelationApprovalPending,
      policyRequestType: "Cancel",
      dealerId: 0,
      remarks: "",
      approvedBy: 0,
    };
    this.policyStatusDetails = {
      id: policyDetails.transactionId,
      statusId: this.policyStatus.CancelationApprovalPending,
    };
    this.confirmModalOpen = true;
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.policyStatusDetails = {};
    this.policyDetails = {};
    this.getPolicyList();
  }

  sendCancelRequest() {
    //Same month policy can be auto cancelled without admin approval
    let todayDate = new Date();
    let sentOnDate = new Date(this.policyPaymentDate);
    sentOnDate.setDate(sentOnDate.getDate());

    if (
      this.userPermission.cancelPolicy &&
      (this.userPermission.cancelPolicyApprove ||
        sentOnDate.getMonth() == todayDate.getMonth())
    )
      this.instantCancel();
    else {
      this.authService
        .post(this._apiUrl + "/api/v1/PolicyRequestLogs", this.policyDetails)
        .subscribe(
          (response) => {
            this.updatePolicyStatus();
          },
          (error) => {}
        );
    }
  }

  updatePolicyStatus() {
    this.authService
      .put(
        this._apiUrl + "/api/v1/PolicyDetails/StatusUpdate",
        this.policyStatusDetails
      )
      .subscribe(
        (response) => {
          this.messageToastService.open(
            "Policy Cancellation Request Submitted",
            { context: "success", duration: 5000 }
          );
          this.confirmModalClose();
        },
        (error) => {}
      );
  }

  instantCancel() {
    this.policyStatusDetails = {
      id: this.policyDetails.transactionId,
      statusId: this.policyStatus.Cancel,
    };
    //update cancel status in unity first
    this.authService
      .put(
        this._apiUrl + "/api/v1/Unity/CancelPolicyFromUnity",
        this.policyDetails.transactionId
      )
      .pipe(
        catchError((error) =>
          this.errorHandler.handleCustomError(
            "Something went wrong when cancelling policy in unity,Please try again!"
          )
        ),
        map((response) => {
          //todo with response
          this.confirmModalOpen = false;
        }),
        //update cancel status in policy table directly
        mergeMap(() =>
          this.authService
            .put(
              this._apiUrl + "/api/v1/PolicyDetails/StatusUpdate",
              this.policyStatusDetails
            )
            .pipe(
              catchError((error) =>
                this.errorHandler.handleCustomError(
                  "Something went wrong when cancelling policy,Please try again!"
                )
              )
            )
        ),
        map((response) => {
          //to-do with response
        })
      )
      .subscribe(
        (response) => {
          this.messageToastService.open("Policy Cancelled Successfully", {
            context: "success",
            duration: 5000,
          });
          this.confirmModalClose();
        },
        (error) => {}
      );
  }

  calculateDiff(sentOn, policyStatus) {
    if (
      this.userPermission.cancelPolicy &&
      this.userPermission.cancelPolicyApprove
    ) {
      // if not having mentioned permissions then datelock will check, else date lock will not consider
      if (
        policyStatus == PolicyStatus.CancelationApprovalPending ||
        policyStatus == PolicyStatus.Cancel ||
        policyStatus == PolicyStatus.ModificationApprovalPending
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      let todayDate = new Date();
      let sentOnDate = new Date(sentOn);
      sentOnDate.setDate(sentOnDate.getDate());

      // let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
      // // To calculate the no. of days between two dates
      // let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if (
        sentOnDate.getMonth() != todayDate.getMonth() ||
        policyStatus == PolicyStatus.CancelationApprovalPending ||
        policyStatus == PolicyStatus.Cancel ||
        policyStatus == PolicyStatus.ModificationApprovalPending
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  public eInvoiceStatus(policy: any): boolean {
    this._eInvoiceStatus=false;
    if (policy.gstDocPathForFinance != null) {
      if (policy.gstInvoicePath != null) {
        this._eInvoiceStatus = true;
      } else {
        this._eInvoiceStatus = false;
      }
    }
    return this._eInvoiceStatus;
  }

  downloadEinvoice($event, policyNo) {
    this.authService
      .export(this._apiUrl + "/api/v1/DownLoadPage/einvoice/" + policyNo)
      .subscribe((data) => saveAs(data, policyNo + `_e_invoice.pdf`));
  }

  isModifiable(policyStatus) {
    if (
      policyStatus == PolicyStatus.CancelationApprovalPending ||
      policyStatus == PolicyStatus.Cancel ||
      policyStatus == PolicyStatus.ModificationApprovalPending
    ) {
      return true;
    } else {
      return false;
    }
  }
}
