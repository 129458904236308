<app-header></app-header>
<app-menu></app-menu>
<!-- <app-title [desktopTitle]="'Users'"></app-title> -->
<div nxLayout="grid" class="nx-margin-top-2m">
  <div nxRow nxRowAlignItems="start,start,center,center">
    <div nxCol="12,6,4,3" nxColOffset="1">
      <nx-formfield>
        <input
          nxInput
          placeholder="Enter Keyword"
          [(ngModel)]="searchTerm"
          type="search"
        />
        <span nxFormfieldPrefix>
          <nx-icon name="search" size="s"></nx-icon>
        </span>
        <span
          nxFormfieldSuffix
          *ngIf="searchTerm"
          aria-hidden="true"
          (click)="searchTerm = ''; getUserList()"
        >
          <nx-icon name="close" size="s"></nx-icon>
        </span>
      </nx-formfield>
    </div>
    <div nxCol="12,6,4,3" class="nx-margin-top-2xs">
      <button
        nxButton="primary small-medium"
        type="button"
        (click)="getUserList()"
      >
        Search
      </button>
    </div>
    <div nxCol="12,6,4,2">
      <nx-formfield nxLabel="Select Client" *ngIf="userRole == 'SysAdmin'">
        <nx-dropdown
          #selectedClient
          (selectionChange)="onClientChanges($event.value)"
        >
          <nx-dropdown-item
            *ngFor="let item of clientList | isActiveCheck"
            [nxValue]="item.clientId"
          >
            {{ item.clientName }}
          </nx-dropdown-item>
        </nx-dropdown>
      </nx-formfield>
    </div>
    <div nxCol="12,6,4,2" class="nx-margin-top-2xs" style="float: right">
      <button
        (click)="toggleModal(false, 0)"
        nxButton="secondary "
        type="button"
      >
        Add User
      </button>
    </div>
  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable condensed nxSort (sortChange)="sortTable($event)">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="firstName">First Name</th>
            <th nxHeaderCell nxSortHeaderCell="lastName">Last Name</th>
            <th nxHeaderCell nxSortHeaderCell="emailId">Email Id</th>
            <th nxHeaderCell nxSortHeaderCell="userRole">Role</th>

            <th nxHeaderCell nxSortHeaderCell="isActive">Status</th>
            <th nxHeaderCell>Edit</th>
            <th nxHeaderCell>Activate/Deactivate</th>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr nxTableRow *ngFor="let item of userList | filterUserRole">
            <td nxTableCell>{{ item.firstName }}</td>
            <td nxTableCell>{{ item.lastName }}</td>
            <td nxTableCell>{{ item.emailId }}</td>
            <td nxTableCell>{{ item.userRole }}</td>
            <td nxTableCell *ngIf="item.isActive == '1'; else inActive">
              <nx-badge vibrant type="positive">Active</nx-badge>
            </td>
            <ng-template #inActive>
              <td nxTableCell>
                <nx-badge vibrant type="critical">In Active</nx-badge>
              </td>
            </ng-template>
            <td nxTableCell>
              <button
                nxIconButton="tertiary  small"
                (click)="toggleModal(true, item.identifier)"
              >
                <nx-icon name="edit" size="s"> </nx-icon>
              </button>
            </td>
            <td nxTableCell *ngIf="item.isActive == '1'">
              <nx-switcher
                [checked]="true"
                (change)="confirmModal(item.identifier, $event.target.checked)"
              ></nx-switcher>
            </td>
            <td nxTableCell *ngIf="item.isActive == '0'">
              <nx-switcher
                [checked]="false"
                (change)="confirmModal(item.identifier, $event.target.checked)"
              ></nx-switcher>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right">
    <div nxCol="6">
      <nx-pagination
        [nxCount]="count"
        [nxPage]="pageNumber"
        [nxPerPage]="pageSize"
        nxType="advanced"
        (nxGoPrev)="prevPage()"
        (nxGoNext)="nextPage()"
        (nxGoPage)="goToPage($event)"
      >
      </nx-pagination>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!--Modal for create user-->
<nx-modal
  #addUserModal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="userModalOpen"
  [nxShowCloseIcon]="false"
>
  <div *ngIf="!isSuccess">
    <form [formGroup]="userForm">
      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h2 nxHeadline="section" *ngIf="!EDIT_FLAG">Add User</h2>
          <h2 nxHeadline="section" *ngIf="EDIT_FLAG">Edit User</h2>
        </div>

        <div nxRow>
          <div
            nxCol="12"
            nxAlignSelf="center"
            [ngClass]="{ disabledbutton: EDIT_FLAG }"
          >
            <nx-formfield nxLabel="Select User Role">
              <nx-dropdown
                formControlName="userTypeId"
                #selectedRole
                (selectionChange)="onUserTypeIdChanges($event.value)"
              >
                <nx-dropdown-item
                  *ngFor="
                    let item of userTypeList | isActiveCheck | filterUserRoleDD
                  "
                  [nxValue]="item.userTypeId"
                  >{{ item.userType }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError> Please select an option. </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow *ngIf="userBaseTypeId != 4">
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="First Name">
              <input nxInput formControlName="firstName" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Last Name">
              <input nxInput formControlName="lastName" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Mobile Number">
              <input nxInput formControlName="mobileNumber" />
              <nx-error nxFormfieldError>
                <div *ngIf="f.mobileNumber.errors?.required">
                  <strong>Please note:</strong>Your Mobile Number is missing.
                </div>
                <div *ngIf="f.mobileNumber.errors?.pattern">
                  <strong>Please note:</strong>Mobile number must have 10
                  Digits.
                </div>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="E-Mail">
              <input nxInput formControlName="emailId" />
              <nx-error nxFormfieldError>
                <div *ngIf="f.emailId.errors?.required">
                  <strong>Please note:</strong> Your email is missing.
                </div>
                <div *ngIf="f.emailId.errors?.pattern">
                  <strong>Please note:</strong> Enter a valid email address.
                </div>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12" nxAlignSelf="center" *ngIf="userBaseTypeId == '3'">
            <nx-formfield nxLabel="Select RSM">
              <nx-dropdown
                formControlName="superUser"
                (selectionChange)="onsuperUserIdChanges($event.value)"
              >
                <nx-dropdown-item
                  *ngFor="let item of superUsersList | isActiveCheck"
                  [nxValue]="item.userId"
                >
                  {{ item.firstName + " " + item.lastName }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <div *ngIf="f.superUser.errors?.required">
                  <strong>Please note:</strong> This field is required!
                </div>
              </nx-error>
              <nx-icon
                nxFormfieldAppendix
                name="info-circle-o"
                [nxPopoverTriggerFor]="popoverRSM"
                nxPopoverTrigger="hover"
              >
              </nx-icon>
            </nx-formfield>
          </div>
          <div nxCol="12" nxAlignSelf="center" *ngIf="isShowZone()">
            <nx-formfield nxLabel="Select Zones">
              <nx-dropdown formControlName="zoneIds" [nxIsMultiselect]="true">
                <nx-dropdown-item
                  *ngFor="let zone of zoneList | isActiveCheck"
                  [nxValue]="zone.id"
                >
                  {{ zone.zone }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-icon
                nxFormfieldAppendix
                name="info-circle-o"
                [nxPopoverTriggerFor]="popoverZone"
                nxPopoverTrigger="hover"
              >
              </nx-icon>
              <nx-error nxFormfieldError> Please select an option. </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12" nxAlignSelf="center" *ngIf="EDIT_FLAG">
            <nx-formfield nxLabel="Password">
              <input
                nxInput
                #testInput
                type="password"
                formControlName="newPassword"
              />
              <nx-password-toggle
                [control]="testInput"
                ariaLabel="Your custom show password label"
                nxFormfieldSuffix
              >
              </nx-password-toggle>
              <nx-error nxFormfieldError>
                <div *ngIf="f.newPassword.errors?.pattern">
                  <strong>Please note:</strong>Should have 1
                  Upper,Lower,Numeric,Special characters
                </div>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12" style="text-align: center">
            <button
              *ngIf="!EDIT_FLAG"
              (click)="addUser()"
              [disabled]="userForm.invalid"
              nxButton="primary"
              type="button"
            >
              Add
            </button>
            <button
              *ngIf="EDIT_FLAG"
              (click)="updateUser()"
              [disabled]="userForm.invalid"
              nxButton="primary"
              type="button"
            >
              Update
            </button>
            <button
              (click)="toggleModal(false, 0)"
              nxButton="secondary"
              type="button"
            >
              Close
            </button>
          </div>
          <!-- <nx-spinner nxSize="medium"></nx-spinner> -->
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around">
      <!-- <div nxCol="12" id="successModalImage" class="success_image">
      </div> -->
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
        <h3
          class="u-text-align-center"
          nxHeadline="subsection-xsmall"
          *ngIf="!EDIT_FLAG"
        >
          User added successfully
        </h3>
        <h3
          class="u-text-align-center"
          nxHeadline="subsection-xsmall"
          *ngIf="EDIT_FLAG"
        >
          User updated successfully
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal(false, 0)" nxButton="block primary">
          close
        </button>
      </div>
    </div>

    <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
  </div>
</nx-modal>

<!--Modal for confirmation -->
<nx-modal
  #confirmModal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="confirmModalOpen"
  [nxShowCloseIcon]="false"
>
  <div nxRow nxRowJustify="start,around">
    <h2 nxHeadline="page">Confirmation</h2>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
    <div nxCol="12">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
        Are you sure you want to do this action?
      </h3>
    </div>
  </div>
  <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
    <div nxCol="6">
      <button (click)="changeUserStatus()" nxButton="block primary">Yes</button>
    </div>
    <div nxCol="6">
      <button (click)="confirmModalClose()" nxButton="block primary">No</button>
    </div>
  </div>
  <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
</nx-modal>

<nx-popover #popoverRSM>
  <div style="max-width: 200px">
    Zones will be loaded for selected RSM User.
  </div>
</nx-popover>
<nx-popover #popoverASM>
  <div style="max-width: 200px">
    Zones will be loaded for selected ASM User.
  </div>
</nx-popover>
<nx-popover #popoverZone>
  <div style="max-width: 200px">
    Zones will change upon change of Zone in Super user
  </div>
</nx-popover>
