<div class="table-container">
  <table
    nxTable
    zebra
    nxSort
    (sortChange)="sortTable($event)"
    style="text-align: center"
  >
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell nxSortHeaderCell="srNo">Sr.No</th>
        <th nxHeaderCell nxSortHeaderCell="rsm">RSM</th>
        <th nxHeaderCell nxSortHeaderCell="asmName">Area Manager</th>
        <th nxHeaderCell nxSortHeaderCell="dealerCode">Dealer Code</th>
        <th nxHeaderCell nxSortHeaderCell="dealerName">Dealer Name</th>
        <th nxHeaderCell nxSortHeaderCell="city">City</th>
        <th nxHeaderCell nxSortHeaderCell="state">State</th>
        <th nxHeaderCell nxSortHeaderCell="1">1</th>
        <th nxHeaderCell nxSortHeaderCell="2">2</th>
        <th nxHeaderCell nxSortHeaderCell="3">3</th>
        <th nxHeaderCell nxSortHeaderCell="4">4</th>
        <th nxHeaderCell nxSortHeaderCell="5">5</th>
        <th nxHeaderCell nxSortHeaderCell="6">6</th>
        <th nxHeaderCell nxSortHeaderCell="7">7</th>
        <th nxHeaderCell nxSortHeaderCell="8">8</th>
        <th nxHeaderCell nxSortHeaderCell="9">9</th>
        <th nxHeaderCell nxSortHeaderCell="10">10</th>
        <th nxHeaderCell nxSortHeaderCell="11">11</th>
        <th nxHeaderCell nxSortHeaderCell="12">12</th>
        <th nxHeaderCell nxSortHeaderCell="13">13</th>
        <th nxHeaderCell nxSortHeaderCell="14">14</th>
        <th nxHeaderCell nxSortHeaderCell="15">15</th>
        <th nxHeaderCell nxSortHeaderCell="16">16</th>
        <th nxHeaderCell nxSortHeaderCell="17">17</th>
        <th nxHeaderCell nxSortHeaderCell="18">18</th>
        <th nxHeaderCell nxSortHeaderCell="19">19</th>
        <th nxHeaderCell nxSortHeaderCell="20">20</th>
        <th nxHeaderCell nxSortHeaderCell="21">21</th>
        <th nxHeaderCell nxSortHeaderCell="22">22</th>
        <th nxHeaderCell nxSortHeaderCell="23">23</th>
        <th nxHeaderCell nxSortHeaderCell="24">24</th>
        <th nxHeaderCell nxSortHeaderCell="25">25</th>
        <th nxHeaderCell nxSortHeaderCell="26">26</th>
        <th nxHeaderCell nxSortHeaderCell="27">27</th>
        <th nxHeaderCell nxSortHeaderCell="28">28</th>
        <th nxHeaderCell nxSortHeaderCell="29">29</th>
        <th nxHeaderCell nxSortHeaderCell="30">30</th>
        <th nxHeaderCell nxSortHeaderCell="31">31</th>
        <th nxHeaderCell nxSortHeaderCell="total">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr nxTableRow *ngFor="let item of ReportData">
        <td nxTableCell>{{ item.srNo }}</td>
        <td nxTableCell>{{ item.rsm }}</td>
        <td nxTableCell>{{ item.asm }}</td>
        <td nxTableCell>{{ item.dealerCode }}</td>
        <td nxTableCell>{{ item.dealerName }}</td>
        <td nxTableCell>{{ item.city }}</td>
        <td nxTableCell>{{ item.state }}</td>
        <td nxTableCell>{{ item.d1 }}</td>
        <td nxTableCell>{{ item.d2 }}</td>
        <td nxTableCell>{{ item.d3 }}</td>
        <td nxTableCell>{{ item.d4 }}</td>
        <td nxTableCell>{{ item.d5 }}</td>
        <td nxTableCell>{{ item.d6 }}</td>
        <td nxTableCell>{{ item.d7 }}</td>
        <td nxTableCell>{{ item.d8 }}</td>
        <td nxTableCell>{{ item.d9 }}</td>
        <td nxTableCell>{{ item.d10 }}</td>
        <td nxTableCell>{{ item.d11 }}</td>
        <td nxTableCell>{{ item.d12 }}</td>
        <td nxTableCell>{{ item.d13 }}</td>
        <td nxTableCell>{{ item.d14 }}</td>
        <td nxTableCell>{{ item.d15 }}</td>
        <td nxTableCell>{{ item.d16 }}</td>
        <td nxTableCell>{{ item.d17 }}</td>
        <td nxTableCell>{{ item.d18 }}</td>
        <td nxTableCell>{{ item.d19 }}</td>
        <td nxTableCell>{{ item.d20 }}</td>
        <td nxTableCell>{{ item.d21 }}</td>
        <td nxTableCell>{{ item.d22 }}</td>
        <td nxTableCell>{{ item.d23 }}</td>
        <td nxTableCell>{{ item.d24 }}</td>
        <td nxTableCell>{{ item.d25 }}</td>
        <td nxTableCell>{{ item.d26 }}</td>
        <td nxTableCell>{{ item.d27 }}</td>
        <td nxTableCell>{{ item.d28 }}</td>
        <td nxTableCell>{{ item.d29 }}</td>
        <td nxTableCell>{{ item.d30 }}</td>
        <td nxTableCell>{{ item.d31 }}</td>
        <td nxTableCell>{{ item.total }}</td>
      </tr>

      <tr nxTableRow *ngIf="count == 0">
        <td nxTableCell colspan="16">
          <ng-container>
            <div nxRow nxRowJustify="center">
              <div nxCol="12" class="nosearchresult"></div>
              <h3
                nxHeadline="subsection-medium"
                class="nx-font-weight-semibold"
              >
                No Records found !!!
              </h3>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  nxRow
  class="nx-margin-top-2m nx-margin-right-5xl"
  style="float: right"
  *ngIf="count != 0"
>
  <div nxCol="6">
    <nx-pagination
      [nxCount]="count"
      [nxPage]="pageNumber"
      [nxPerPage]="pageSize"
      nxType="advanced"
      (nxGoPrev)="prevPage()"
      (nxGoNext)="nextPage()"
      (nxGoPage)="goToPage($event)"
    >
    </nx-pagination>
  </div>
</div>
