import { Component, OnInit, Inject, DebugElement } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
} from "@angular/forms";
import {
  policyPosttData,
  sellPolicyData,
} from "../addpolicy/sellpolicyData.model";
import { DatastorageService } from "../datastorage.service";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { Router } from "@angular/router";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { EventEmitterService } from "../event-emitter.service";
import { WalletRefreshService } from "../walletbalance/walletbalance-service";
import { LoaderService } from "../loader.service";
import { Step } from "../core/step.model";
import { catchError, map, mergeMap } from "rxjs/operators";
import { throwError } from "rxjs";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { of } from "rxjs";
import { RequestIntegrity } from "../core/request-integrety";

@Component({
  selector: "app-paymentdetails",
  templateUrl: "./paymentdetails.component.html",
  styleUrls: ["./paymentdetails.component.css"],
})
export class PaymentdetailsComponent implements OnInit {
  paymentDetailForm: FormGroup;
  sellPolicyData: sellPolicyData = new sellPolicyData();
  _apiUrl: string;
  clientId: number;
  currentDate = new Date();
  Messages: any;
  policyNo: any;
  cessList: any;
  cessTotal: number = 0;
  isPolicyDocCreated: boolean = false;
  BuyNowText: string = "Buy Now";
  buyPolicyInProgress: boolean;
  step: Step = new Step();
  isWalletlow: boolean = false;
  policypostdata: policyPosttData = new policyPosttData();
  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private dataService: DatastorageService,
    private router: Router,
    private messageToastService: NxMessageToastService,
    private eventEmitterService: EventEmitterService,
    private walletRefreshService: WalletRefreshService,
    private loaderService: LoaderService,
    private errorHandler: CustomErrorHandler,
    private requestIntegrity: RequestIntegrity
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.Messages = MESSAGE_CONSTS;
    //this.sellPolicyData.paymentMethod = 'wallet';
  }
  ngOnInit() {
    this.buyPolicyInProgress = false;
    this.getCessandTaxesForState();
    this.paymentFormBuild();
    // this.getPaymentDetails();
    this.initializeNav(2);
  }

  initializeNav(compltedStep: number) {
    for (let i = 0; i <= compltedStep; i++) {
      this.step.stepNumber = i;
      this.step.completed = true;
      this.dataService.nextClick.next(this.step);
    }
  }

  paymentFormBuild() {
    this.paymentDetailForm = new FormBuilder().group({
      paymentMethod: [
        this.sellPolicyData.paymentMethod == undefined
          ? "wallet"
          : this.sellPolicyData.paymentMethod,
        Validators.required,
      ],
      soldBy: [this.sellPolicyData.soldBy, [Validators.required,Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)]],
      soldByNumber: [
        this.sellPolicyData.soldByNumber,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ],
      unitPrice: this.sellPolicyData.unitPrice,
      outstandingBal: this.sellPolicyData.outstandingBal,
    });
  }

  get f() {
    return this.paymentDetailForm.controls;
  }

  savePaymentDetails() {
    this.loaderService.show();
    this.buyPolicyInProgress = true;
    this.BuyNowText = "Please wait...";

    this.sellPolicyData.paymentMethod =
      this.paymentDetailForm.get("paymentMethod").value;
    this.sellPolicyData.paymentDate = this.currentDate;
    this.sellPolicyData.soldBy = this.paymentDetailForm.get("soldBy").value;
    this.sellPolicyData.soldByNumber =
      this.paymentDetailForm.get("soldByNumber").value;
    this.sellPolicyData.unitPrice =
      this.paymentDetailForm.get("unitPrice").value;
    this.sellPolicyData.outstandingBal =
      this.paymentDetailForm.get("outstandingBal").value;
    this.dataService.setData(this.sellPolicyData);
    Object.assign(this.policypostdata, this.sellPolicyData);
    this.buyPolicy();
  }

  getPaymentDetails(cessList: any) {
    this.cessTotal = 0;
    cessList.forEach((element) => {
      this.cessTotal += Math.round(
        (this.sellPolicyData.price * element.cessPercentage) / 100
      );
    });
    this.sellPolicyData.unitPrice =
      this.sellPolicyData.price + this.sellPolicyData.gstPrice + this.cessTotal;
    this.sellPolicyData.paymentDate = this.currentDate;
    this.getWalletBalance();
    this.paymentFormBuild();
    // }
  }

  getCessandTaxesForState() {
    let resp = this.dataService.getData();
    if (resp) {
      this.sellPolicyData = resp;
      this.sellPolicyData.soldBy = null;
      this.sellPolicyData.soldByNumber = null;
      this.sellPolicyData.policyNo = null;
      this.authService
        .get(this._apiUrl + `/api/v1/CessTaxes/DealerId`)
        .subscribe(
          (response) => {
            this.cessList = response;
            this.getPaymentDetails(this.cessList);
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  getWalletBalance() {
    this.authService
      .get(this._apiUrl + "/api/v1/WalletDetails/GetWalletBalance")
      .subscribe(
        (response) => {
          if (response) {
            this.sellPolicyData.outstandingBal = response.data;
            this.paymentFormBuild();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  buyPolicy() {
    //To ensure uniqueness in each request
    this.policypostdata.transactionId = this.requestIntegrity.uuidv4();
    this.policypostdata.hash = this.requestIntegrity.HashInput(
      this.policypostdata.transactionId
    );

    this.isPolicyDocCreated = false;
    this.getPaymentDetails(this.cessList);
    this.step.stepNumber = 3;
    this.step.completed = true;
    this.dataService.nextClick.next(this.step);
    var result: any;

    //if (this.sellPolicyData.outstandingBal >= this.sellPolicyData.unitPrice) {
    this.authService
      .post(
        this._apiUrl + "/api/v2/PolicyDetails/addwithoutwallet",
        this.policypostdata
      )
      .pipe(
        map((response) => {
          if (response.success) {
            result = response;
            this.policyNo = response.policyNo;
            this.walletRefreshService.walletRefresh(true);
            this.dataService.clearData(this.sellPolicyData);
          } else {
            this.buyPolicyInProgress = false;
            this.BuyNowText = "Buy Now";
            this.errorHandler.handleCustomError(response.responsemsg);
            return; //exist from from all further request processing
          }
        }),

        // Trigger call to create policy document
        mergeMap(() =>
          this.authService
            .export(
              this._apiUrl + `/api/v1/DownLoadPage/policyNo/${result.transactionId}`
            )
            .pipe(
              catchError((error) =>
                this.errorHandler.handleCustomInfo(
                  "Policy Punched Successfully! Generating policy document is failed, Please download from Manage Policy!"
                )
              )
            )
        ),
        map((response) => {
          if (response != null) {
            this.isPolicyDocCreated = true;
          }
        }),

        // Trigger call to send policy document
        mergeMap(() =>
          this.authService
            .get(
              this._apiUrl +
                `/api/v1/PolicyDetails/sendmail/policyId/${result.transactionId}`
            )
            .pipe(
              catchError((error) =>
                this.errorHandler.handleCustomInfo(
                  "Policy document failed to send via Email,Please Download Later!"
                )
              )
            )
        ),
        map((response) => {
          //  if(this.isPolicyDocCreated){
          this.router.navigate([
            "/sellpolicy/download/" + result.transactionId,
          ]);
          //  }
        }),

        // Trigger call to push policy to unity(APD)
        // mergeMap(() =>
        //   this.authService
        //     .post(this._apiUrl + "/api/v1/Unity/Push", result.data)
        //     .pipe(
        //       catchError((error) =>
        //         this.errorHandler.handleCustomInfo(
        //           "Something went wrong while pushing policy to unity!We Will try to push after sometime!"
        //         )
        //       )
        //     )
        // ),
        // map((data) => {
        //   if (data.success) {
        //     // console.log('unity updated');
        //   }
        // }),

        // Trigger call to push policy to unity(APD) --Using API
        //  , mergeMap(() => this.authService.post(this._apiUrl + '/api/v1/Unity/contracts', result.data).pipe(catchError((error) => this.errorHandler.handleCustomError('Something went wrong while pushing policy to unity!'))))
        //  , map((data) => {
        //     if (data.success) {
        //       // console.log('unity updated');
        //     }
        //   })

        // If there is an exception posting policy break the execution.
        catchError((error) => {
          //this.errorHandler.handleCustomError('Something went wrong while punching policy! Please contact system administrator');
          console.log(
            "Something went wrong while punching policy! Please contact system administrator"
          );
          return throwError(error);
        })
      )
      .subscribe(() => console.log("policy punched successfully. Good job :)"));
    // }
    // else {
    //   this.isWalletlow = true;
    //   this.messageToastService.open(this.Messages.WALLET_BALANCE_ERROR_MSG, {
    //     context: "info",
    //     duration: 5000,
    //   });
    // }
  }

  ngOnDestroy(): void {
    this.loaderService.hide();
  }

  get checkDisable(): boolean {
    return this.paymentDetailForm.invalid || this.buyPolicyInProgress;
  }

  PrevPage() {
    this.step.stepNumber = 2;
    this.step.completed = false;
    this.dataService.nextClick.next(this.step);
  }
}
