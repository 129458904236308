<header nx-header>

  <nx-header-row>
    <nx-header-brand>
      <nx-link>
        <a href="#">
          <figure nxFigure>
            <img
              src="assets/APlogo.png"
              alt="Allianz Global Brand Logo"
              role="none"
            />
          </figure>
          &nbsp;
          &nbsp;
          <div class="headerDivider"></div>
          &nbsp;
          &nbsp;
          <figure nxFigure>
            <img
              src="assets/Client_Logo.png"
              alt="Client Logo"
              role="none"
              style="width: 50px; height: 50px"
            />
          </figure>
        </a>
      </nx-link>
    </nx-header-brand>
  </nx-header-row>
</header>

<nx-small-stage class="nx-margin-bottom-m stage-bg">
  <nx-link nxSmallStageHeader>
    <a routerLink="/">
      <nx-icon name="arrow-left"></nx-icon>
      Back to the Login
    </a>
  </nx-link>
  <nx-small-stage-image nxSmallStageImageStart src="/assets/illustrations/man_asking_question.png"></nx-small-stage-image>
  <nx-small-stage-image nxSmallStageImageEnd src="/assets/illustrations/woman_with_locked_computer.png"></nx-small-stage-image>

  <h1 class="nx-margin-bottom-l" nxHeadline="page"><span class="highlighted">Forgot Password?</span></h1>
</nx-small-stage>

<div nxLayout="grid">
  <div nxRow>
    <div
      nxCol="12,12,6,4"
      class="form-fgtpwd nx-margin-top-5xl"
      style="overflow-x: hidden"
    >
      <form [formGroup]="forgotPwdForm" (keydown.enter)="forgotPassword();">
        <div nxRow nxRowJustify="around" class="nx-margin-top-2m">
          <div nxCol="12,10">
            <nx-formfield nxLabel="Email Id">
              <input nxInput formControlName="emailId" />
              <nx-error nxFormfieldError>
                <b>Please note: </b> you have to fill in your email to continue.
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow nxRowJustify="around">
          <div nxCol="12,10,8,6">
            <button nxButton="primary  block" type="button" [disabled]="forgotPwdForm.invalid"
              (click)="forgotPassword();">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
