import { Component, OnInit, Inject, Input, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MESSAGE_CONSTS } from '../ValidationMessages';
import { WalletStatus } from '../core/status.model';
import { walletListData } from './walletListData.model';
import { NxMessageToastService } from '@aposin/ng-aquila/message';
import { EventEmitterService } from '../event-emitter.service';
import { throwError } from 'rxjs';
import { SortEvent, SortDirection } from '@aposin/ng-aquila/table';
import { WalletRefreshService }  from '../walletbalance/walletbalance-service';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import * as moment from 'moment';
import { RequestIntegrity } from '../core/request-integrety';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { saveAs } from 'file-saver';


declare var bolt: any;


@Component({
    selector: 'app-walletrequestlist',
    templateUrl: './walletrequestlist.component.html',
    styleUrls: ['./walletrequestlist.component.css']
})
export class WalletrequestlistComponent implements OnInit {
    _apiUrl;
    private _base_URL;
    private _payu_base_url: string;
    accpetedTypes:string ="application/pdf,image/*";
    clientId: number;
    walletRequestList;
    Userrole;
    isSuccess: boolean;
    reqDecision: boolean;
    walletRequestView: boolean = false;
    walletRequestFormView: boolean = false;
    walletRequestApproveRejectForm: FormGroup;
    walletRequestForm: FormGroup;
    requestedWalletAmount: number = 0;
    WorkshopId: number = 0;
    WalletStatuses; any;
    walletListData: walletListData = new walletListData();
    public response: { dbPath: '' };
    checkUploadPath: string;
    loadAPI: Promise<any>;
    UserName: string;
    UserEmail: string;
    OnlinewalletRequestFormView: boolean;
    errorMessage: string;
    isFailed: boolean = false;
    hashinput = new Object();
    DealerReg: boolean;
    userPermission :any={};
    userTypeId: number;
    extn:string;
    filename:string;
    @ViewChild('disclaimerTemplate') templateRef: TemplateRef<any>;
    dialogRef: NxModalRef<any>;
    actionResult: string;
    paymentbuttontext: string;
    IsPaymentIntiated:boolean;
    onlinepayment : boolean;
    SelectedWalletRequestStatus: typeof WalletStatus;
    walletStatus: Array<string>;
    saveWalletText: string ="submit";
    saveWalletInProgress: boolean = false;

    constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService,
       @Inject('BASE_URL') baseURL, private eventEmitterService: EventEmitterService,
        private walletRefreshService : WalletRefreshService, public dialogService: NxDialogService,
        private requestIntegrity : RequestIntegrity, private errorHandler: CustomErrorHandler) {
        this._apiUrl = appConfig.baseUrl;
        this._base_URL = baseURL;
        this._payu_base_url = appConfig.payuUrl;
        this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
        this.Userrole = atob(localStorage.getItem("userRole"));
        this.UserName = localStorage.getItem('USER_NAME');
        this.UserEmail = localStorage.getItem('USER_EMAIL');

        this.WalletStatuses = WalletStatus;
        
        this.DealerReg = (localStorage.getItem("DealerReg") == 'true' && this.Userrole == 'Dealer');
        this.setUserPermission();
    }

    ngOnInit() {

        this.getAllWalletRequestByUserRole();
        this.walletStatus = Object.keys(WalletStatus).filter(key => isNaN(+key));
    }
    /**Pagination Start */
    count: number;
    pageNumber: number = 1;
    pageSize: number = 10;
    searchTerm: any = "";
    prevPage() {
        this.pageNumber--;
        this.getAllWalletRequestByUserRole();
    }

    nextPage() {
        this.pageNumber++;
        this.getAllWalletRequestByUserRole();
    }

    goToPage(n: number) {
        this.pageNumber = n;
        this.getAllWalletRequestByUserRole();
    }
    /**Pagination end */
    /** sorting starts here */

    sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
        this.walletRequestList = this.walletRequestList.sort((a, b) => {
            if (sort.active in a && sort.active in b) {
                return this.compare(a[sort.active], b[sort.active], sort.direction);
            }
            return 0;
        });
    }

    compare(
        a: number | string | Date,
        b: number | string | Date,
        direction: SortDirection) {

        return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
    }
    /** sorting ends here */

    setUserPermission() {
        this.authService
            .userPermissions()
            .subscribe((response) => {
                // console.log('permissions',response)
                if (response) {
                    this.userPermission = response;
                }
            });
    }
    getAllWalletRequestByUserRole() {
        if (this.Userrole === "WorkShop")
            this.getWalletRequestsByWorkshopId();
        else
            this.getAllWalletRequests();
        this.walletRequestFormBuilder();
    }
    
    walletRequestARFormBuilder() {       
        this.walletRequestApproveRejectForm = new FormGroup({
            id: new FormControl(this.walletListData.id),
            workshopId: new FormControl(this.walletListData.workshopId),
            paymentMethod: new FormControl(this.walletListData.paymentMethod),
            paymentDate: new FormControl(this.walletListData.paymentDate),
            paymentMethodDetails: new FormControl(this.walletListData.paymentMethodDetails),
            uploadedDoc: new FormControl(this.walletListData.uploadedDoc),
            clientId: new FormControl(this.walletListData.clientId),
            remark: new FormControl(this.walletListData.remark),
            dealerName: new FormControl(this.walletListData.dealerName),
            dealerCode: new FormControl(this.walletListData.dealerCode),
            approvedRemarks: new FormControl('', [Validators.required]),
            requestedAmount: new FormControl(''),
            approvedby: new FormControl(''),
            approvedDate: new FormControl(''),
            grantedAmt: new FormControl('', [Validators.required, (control: AbstractControl) => Validators.max(this.requestedWalletAmount)(control), Validators.pattern(MESSAGE_CONSTS.NUMBER_REGEX)]),
            status: new FormControl(''),  
            transactionId: new FormControl(''),  
        });
    }

    walletRequestFormBuilder() {
        //To ensure uniqueness in each request
        var transactionId = this.requestIntegrity.uuidv4();

        this.walletRequestForm = new FormGroup({
            walletDetailsId: new FormControl(0),
            workshopId: new FormControl(0),
            paymentMethod: new FormControl('',[Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]),
            paymentDate: new FormControl('', [Validators.required]),
            paymentMethodDetails: new FormControl('',[Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]),
            uploadedDoc: new FormControl('', [Validators.required]),
            clientId: new FormControl(this.clientId),
            requestedAmount: new FormControl('', [Validators.required, Validators.pattern(MESSAGE_CONSTS.NUMBER_REGEX)]),
            remark: new FormControl('', [Validators.required,Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]),
            status: new FormControl(0),
            mobileNo: new FormControl('', [Validators.required, Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX)]),
            transactionId : new FormControl(transactionId),
            hash : new FormControl(this.requestIntegrity.HashInput(transactionId)),
        });
    }


    get ARFormControls() {
        return this.walletRequestApproveRejectForm.controls;
    }
    get RequestFormControls() {
        return this.walletRequestForm.controls;
    }

    // check payment proceed button to enable or disable
    checkpaybutton()
    {
        return  this.walletRequestForm.invalid || this.IsPaymentIntiated;
    }
    /**
     * to get all wallet requests for admin users
     */
    getAllWalletRequests() {
        var status = this.SelectedWalletRequestStatus == undefined ? "" : this.SelectedWalletRequestStatus;
        this.authService.getList(this._apiUrl + `/api/v1/WalletDetails?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&udf1=${status}`)
        .subscribe(response => {
                if (response) {
                    this.walletRequestList = response.body;
                    var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
                    this.count = pagingProp.TotalCount;
                }
            }, (error) => {
                console.error(error);
                this.handleError(error);
            });
    }

    /**
     * to get wallet requests for workshop/dealer users
     */
    getWalletRequestsByWorkshopId() {

        this.authService.getList(this._apiUrl + '/api/v1/WalletDetails/workshop' +
            "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
                if (response) {
                    this.walletRequestList = response.body;
                    var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
                    this.count = pagingProp.TotalCount;
                }
            },
                (error) => {
                    console.error(error);
                    this.handleError(error);
                });
    }
    /**
     * to save approval/rejection process
     */
    saveARWalletRequest(type: any) {
        this.saveWalletInProgress = true;
        this.saveWalletText = 'Please wait...';
        this.authService.put(this._apiUrl + '/api/v1/WalletDetails/' + this.walletRequestApproveRejectForm.value.id, this.walletRequestApproveRejectForm.value)
        .subscribe(response => {
            this.saveWalletInProgress = false;
            this.saveWalletText = 'Submit';
            this.isSuccess = true;
            this.refreshgrid(type);
        }, (error) => {
            this.saveWalletInProgress = false;
            this.saveWalletText = 'Submit';
            this.walletRequestView = false;
            this.refreshgrid(type);
            //this.handleError(error);
        });
    }

    refreshgrid(type:string)
    {
        if (type == "offline") {
            if (this.Userrole === "WorkShop")
                this.getWalletRequestsByWorkshopId();
            else
                this.getAllWalletRequests();
        }
    }
    /**
     * to show/hide approval/rejection modal
     */
    toogleApproveRejectModal(mode: boolean, request) {
        this.reqDecision = mode;
        this.isSuccess = false;
        this.walletRequestView = !this.walletRequestView;
        this.requestedWalletAmount = request.requestedAmount;
        this.walletListData = request;
        this.walletRequestARFormBuilder();

        this.walletRequestApproveRejectForm.patchValue({
            "requestedAmount": request.requestedAmount,
            "approvedDate": new Date(),
            "status": mode == true ? 1 : 2,
            "paymentMethod":request.paymentMethod,
            "paymentMethodDetails":request.paymentMethodDetails,
            "paymentDate":moment(request.paymentDate).format('MM-DD-YYYY'),
            "remark":request.remark,
            "transactionId":request.transactionId
        });
        let str=request.uploadedDoc;
        this.filename=str.split(/_(.+)/)[1];
        this.extn=str.substring(str.lastIndexOf(".") + 1, str.length);
        if (!mode) {
            this.walletRequestApproveRejectForm.get('grantedAmt').clearValidators();
            this.walletRequestApproveRejectForm.get('grantedAmt').setValue(0);
        }
        this.walletRequestApproveRejectForm.updateValueAndValidity();

    }
    togglePaymentModalPopup(type: string) {
        this.isSuccess = false;
        this.isFailed = false;
        this.paymentbuttontext ='Proceed to Pay';
        this.IsPaymentIntiated=false;
        this.walletRequestFormBuilder();
        if (type === "offline") {
            this.walletRequestFormView = !this.walletRequestFormView;
            this.walletRequestForm.get('mobileNo').clearValidators();
        }
        else if (type === "online") {
            this.onlinepayment=true;
            this.OnlinewalletRequestFormView = !this.OnlinewalletRequestFormView;
            this.walletRequestForm.get('remark').clearValidators();
            this.walletRequestForm.get('paymentDate').clearValidators();
            this.walletRequestForm.get('uploadedDoc').clearValidators();
           // this.eventEmitterService.invokeWalletBalance();
            this.walletRefreshService.walletRefresh(true);
        }
        this.walletRequestForm.updateValueAndValidity();
    }
    toggleWalletARModalPopup() {
        this.walletRequestView = !this.walletRequestView;
        this.walletRequestARFormBuilder();
    }

    saveWalletRequest(type: any) {
        this.saveWalletInProgress = true;
            this.saveWalletText = 'Please Wait...';
        if (type == "offline") {
            this.walletRequestForm.patchValue({ "uploadedDoc": this.checkUploadPath });
            this.walletRequestForm.updateValueAndValidity();
        }

        if (this.walletRequestForm.valid) {
            this.authService.post(this._apiUrl + '/api/v1/WalletDetails', btoa(JSON.stringify(this.walletRequestForm.value))).subscribe(response => {
                this.saveWalletInProgress = false;
                this.saveWalletText = 'Submit';
                if (type == "offline") {
                    this.isSuccess = true;
                }
                else {
                    if (this.walletRequestForm.value.status == 1)
                        this.isSuccess = true;
                }
                if (this.Userrole === "WorkShop")
                    this.getWalletRequestsByWorkshopId();
                else
                    this.getAllWalletRequests();
                // this.eventEmitterService.invokeWalletBalance();
                this.walletRefreshService.walletRefresh(true);
            }, (error) => {
                this.saveWalletInProgress = false;
                this.saveWalletText = 'Submit';
                this.handleError(error);
            });
        }
        else {
            // console.log('this form is invalid');
        }
    }

    public checkUploadFinished = (event) => {
        this.response = event;
        this.checkUploadPath = this.response.dbPath;
        this.walletRequestForm.patchValue({ "uploadedDoc": this.checkUploadPath });
    }


    get isOfflinePayment(){
        
        if(this.userPermission.isOfflinePayment){

            if(this.Userrole == 'WorkShop' || this.DealerReg){
                return true;
            }
        }

        return false;
    }

    openFromTemplate(): void {
        this.dialogRef = this.dialogService.open(this.templateRef, { ariaLabel: 'Disclaimer modal', disableClose: true, width: '600px' });
    
        this.dialogRef.afterClosed().subscribe(result => {
            if(result=='Agree') this.togglePaymentModalPopup('offline')
        });
    }
    
    closeDialog(result: string) {
       this.dialogRef.close(result);
    }

    onCloseModal(arg : boolean){
        this.onlinepayment = arg;
        this.getAllWalletRequestByUserRole();
    }

    download(path: string) {
        this.authService.export(this._apiUrl + `/api/v1/common/stream?filePath=${path}`)
        .subscribe(response =>{
          if(response.size>0)
          {
            var filename=path.split(/_(.+)/)[1];
            saveAs(response,filename)
          }
          else 
          {
            this.errorHandler.handleCustomError("File not found,Contact admin!!");
          }
        }, (error) => {
            this.errorHandler.handleCustomError(error);
        });
      }

    handleError(error, custmsg: string = "") {
        if (error.error instanceof ErrorEvent) {
            // client-side error
            this.errorMessage = `Error : ${error.error.message}`;
        } else {
            // server-side error
            this.errorMessage = `Error : ${error.error}`;
        }
        if (custmsg != "" || custmsg != null) {
            this.errorMessage = `Error : ${custmsg}`;
        }
        this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
        return throwError(this.errorMessage);

    }
    get checksavewalletDisable() : boolean{

        return this.walletRequestApproveRejectForm.invalid || this.saveWalletInProgress;
    }
    get checkDisable() : boolean{

    return this.walletRequestForm.invalid || this.saveWalletInProgress;
    }
}

