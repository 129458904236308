import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { MESSAGE_CONSTS } from '../ValidationMessages';
import { WalletRefreshService } from '../walletbalance/walletbalance-service';

declare var bolt: any;

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.css']
})
export class OnlinePaymentComponent implements OnInit {

  walletRequestForm: FormGroup;
  hashinput: { key: any; txnid: any; amount: any; firstname: any; email: any; productinfo: string; udf1: any; udf2: any; udf3: any; udf4: any; udf5: any; udf6: any; udf7: any; udf8: any; udf9: any; hash: any; status: any; };
  errorMessage: string;
  isFailed: boolean = false;
  transactionStatus : string;
  closed:boolean = false;
  @Output() closeModal = new EventEmitter<boolean>();
  loadAPI: Promise<any>;
  @ViewChild('template') templateRef: TemplateRef<any>;
  templateDialogRef: NxModalRef<any>;

  private _payu_base_url: string;
  private _base_URL: any;
  RequestData: { key: any; txnid: any; hash: any; amount: any; firstname: any; email: any; phone: any; productinfo: any; udf1: any; udf2: any; udf3: any; surl: string; furl: string; };

  constructor(private walletRefreshService: WalletRefreshService,private authService: AuthService, private appConfig : AppConfig, private errorHandler: CustomErrorHandler,@Inject('BASE_URL') baseURL,public dialogService: NxDialogService) {
    this._payu_base_url = appConfig.payuUrl;
    this._base_URL = baseURL;
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
  });
   }

  ngOnInit(): void {
    this.walletRequestFormBuilder();
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("bolt")) {
            isFound = true;
        }
    }
    if (!isFound) {
        var dynamicScripts = [this._payu_base_url];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.id = 'bolt';
            node.src = dynamicScripts[i];
            node.async = false;
            node.charset = 'utf-8';
            node.setAttribute('bolt-color', '#496EBD');
            node.setAttribute('bolt-logo', this._base_URL + '/assets/Allianz_Logo.png');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
  }
  walletRequestFormBuilder() {
    this.walletRequestForm = new FormGroup({
        requestedAmount: new FormControl('', [Validators.required, Validators.pattern(MESSAGE_CONSTS.NUMBER_REGEX)]),
        mobileNo: new FormControl('', [Validators.required, Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX)]),
    });
  }

  get RequestFormControls() {
    return this.walletRequestForm.controls;
  }

  onModalClose(agreed: boolean) {
    this.closeModal.emit(agreed);
  }

  onClose(){
    this.templateDialogRef.close();
    this.closeModal.emit(false);

  }

  PayNow() {
    this.closeModal.emit(false);
    //build data model for hashing
    //Email and first name getting from server side
    this.hashinput = {
        key: null,
        txnid: Math.floor(Math.random() * 99999).toString(),
        amount: this.walletRequestForm.value.requestedAmount.toString(),
        firstname: null,
        email: null,
        productinfo: "Online wallet recharge",
        udf1: this.walletRequestForm.value.mobileNo.toString(),
        udf2: null,
        udf3: null,
        udf4: null,
        udf5: null,
        udf6: null,
        udf7: null,
        udf8: null,
        udf9: null,
        hash: null,
        status: null
    }
    
    //Response handler for Payu
    var Handler = {
        responseHandler: (BOLT) => {
            if (BOLT.response.txnStatus == "CANCEL") {
              BOLT.response.udf1=this.RequestData.udf1;
              BOLT.response.udf2=this.RequestData.udf2;
              BOLT.response.udf3=this.RequestData.udf3;
              BOLT.response.txnid=this.RequestData.txnid;
              
              this.errorHandler.handleCustomError(MESSAGE_CONSTS.WALLET_PAYMENT_CANCEL_ERROR_MSG);
            }
            else if (BOLT.response.txnStatus == "FAILED") {
              this.isFailed = true;
            } 
            this.checkResponseHash(BOLT.response);
        },
        catchException: (BOLT) => {
            this.errorHandler.handleCustomError(BOLT.message);
            // the code you use to handle the integration errors goes here
        }
    }

    //Hashing
    this.authService.post(this.appConfig.baseUrl + '/api/v1/PaymentGateway/GetHashandKey', this.hashinput).subscribe(response => {
        if (response.success) {
            this.RequestData = {
                key: response.data.key,
                txnid: response.data.txnid,
                hash: response.data.hash,
                amount: response.data.amount,
                firstname: response.data.firstname,
                email: response.data.email,
                phone: response.data.udf1,
                productinfo: response.data.productinfo,
                udf1 : response.data.udf1,
                udf2 : response.data.udf2,
                udf3: response.data.udf3,
                surl: "true",
                furl: "false",
                //enforce_paymethod: response.udf5.toLowerCase()
            }
            bolt.launch(this.RequestData, Handler);
        }
        else {
            this.errorHandler.handleCustomError(response.responsemsg);
        }
      }, (error) => {
          this.errorHandler.handleError(error);
      });
    }

    public checkResponseHash(paymentResponse: any) {
      this.authService.post(this.appConfig.baseUrl + '/api/v1/PaymentGateway/ValidateResponseHash', paymentResponse ).subscribe(response => {      
        
          this.templateDialogRef = this.dialogService.open(this.templateRef, {width : '450px'} );
          this.transactionStatus = response.responsemsg;

          if (response.success) {
            this.walletRefreshService.walletRefresh(true); 
            this.isFailed = true;
            this.closeModal.emit(false);
          }
          else {
              this.errorHandler.handleCustomError(response.responsemsg);
          }
      }, (error) => {
          this.errorHandler.handleError(error)
      });
  }
}
