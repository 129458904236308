import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortEvent, SortDirection } from '@aposin/ng-aquila/table';
import { NxMessageToastService } from '@aposin/ng-aquila/message';
import { MESSAGE_CONSTS } from '../ValidationMessages';

@Component({
  selector: 'app-manage-content',
  templateUrl: './manage-content.component.html',
  styleUrls: ['./manage-content.component.css']
})
export class ManageContentComponent implements OnInit {

  contentModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  ContentForm: FormGroup;
  _apiUrl: string;
  clientId: number;
  contentList = [];
  NotificationTypeList = [];
  NotificationCategoryList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  id;
  contentDetails = {};
  userId: number;
  previewModalOpen: boolean;
  previewContent: any;
  invoicePatterns: boolean = false;

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.ContentFormBuilder();
  }

  /** sorting starts here */

  sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.contentList = this.contentList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getContentList();
  }

  nextPage() {
    this.pageNumber++;
    this.getContentList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getContentList();
  }
  /**Pagination end */
  urlValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = nameRe.exec(control.value);
      return forbidden==null ? {forbiddenName: {value: control.value}} : null;
    };
  }
  ngOnInit() {
    this.getContentList();
    this.getNotificationTypeList();
    this.getNotificationCategoryList(0);
  }

  ContentFormBuilder() {
    this.ContentForm = new FormGroup({
      typeDisplay: new FormControl('', [Validators.required]),
      clientId: new FormControl(this.clientId),
      userId: new FormControl(null),
      subject: new FormControl(null),
      body: new FormControl(null),
      id: new FormControl(0),
      status: new FormControl(true),
      notificationTypeId: new FormControl('', [Validators.required]),
      notificationCategoryId: new FormControl('', [Validators.required]),

    });
  }

  get f() {
    return this.ContentForm.controls;
  }
  getContentList() {
    this.authService.getList(this._apiUrl + '/api/v1/ManageContent/GetAllNotificationContent' + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.contentList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }

  getContentListById(ContentId) {
    this.authService.get(this._apiUrl + '/api/v1/ManageContent/GetNotificationContentbyId?ContentId=' + ContentId).subscribe(response => {
      if (response) {
        // this.contentList = response;
        this.ContentForm.setValue({
          id: response.id,
          typeDisplay: response.typeDisplay,
          clientId: response.clientId,
          subject: response.subject,
          body: response.body,
          status: response.status,
          userId: null,
          notificationTypeId: response.notificationTypeId,
          notificationCategoryId: response.notificationCategoryId,
        })
        this.getNotificationCategoryList(response.notificationTypeId);

      }
    }, (error) => {
      console.error(error);
    });
  }

  getNotificationTypeList() {
    this.authService.get(this._apiUrl + '/api/v1/ManageContent/GetNotificationType').subscribe(response => {
      if (response) {
        this.NotificationTypeList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  getNotificationCategoryList(typeId) {
    this.authService.get(this._apiUrl + '/api/v1/ManageContent/GetNotificationCategory?Type=' + typeId).subscribe(response => {
      if (response) {
        this.NotificationCategoryList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  toggleModal(EDIT: boolean, contentId) {
    this.EDIT_FLAG = EDIT;
    this.id = contentId;
    this.contentModalOpen = !this.contentModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getContentListById(contentId);
    }
    if (!this.contentModalOpen) {
      this.ContentFormBuilder();
    }
  }

  addContentMaster() {
    var regex:RegExp=MESSAGE_CONSTS.URL_REGEX;
    if(regex.test(this.ContentForm.value.body))
    {
      this.messageToastService.open('Content Body Contains URL elements', { context: 'info', duration: 6000 });
      return;
    }
    this.authService.post(this._apiUrl + '/api/v1/ManageContent/SaveNotificationContent', this.ContentForm.value).subscribe(response => {
      if (response) {
        if (response.response) {
          this.getContentList();
          this.ContentFormBuilder();
          this.isSuccess = true;
        }
        else
        {
          this.messageToastService.open(MESSAGE_CONSTS.MANAGE_CONTET_ERROR_MSG, { context: 'info', duration: 6000 });
          this.getContentList();
        }
      }
    }, (error) => {
      console.error(error);
    });
  }



  updateContentMaster() {
    var regex:RegExp=MESSAGE_CONSTS.URL_REGEX;
    if(regex.test(this.ContentForm.value.body))
    {
      this.messageToastService.open(MESSAGE_CONSTS.MANAGE_CONTENT_URL_SANITIZATION, { context: 'info', duration: 6000 });
      return;
    }
    this.authService.put(this._apiUrl + '/api/v1/ManageContent/UpdateNotificationContent', this.ContentForm.value).subscribe(response => {
      if (response) {
        if (response.response) {
          this.getContentList();
          this.ContentFormBuilder();
          this.isSuccess = true;
        }
        else
        {
          this.messageToastService.open(MESSAGE_CONSTS.MANAGE_CONTET_ERROR_MSG, { context: 'info', duration: 5000 });
          this.getContentList();
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getContentList();
    this.ContentFormBuilder();
  }

  confirmModal(contentDetails, isChecked: boolean) {
    this.confirmModalOpen = true;
    this.ContentForm.setValue({
      id: contentDetails.id,
      typeDisplay: contentDetails.typeDisplay,
      clientId: contentDetails.clientId,
      subject: contentDetails.subject,
      body: contentDetails.body,
      status: isChecked,
      userId: null,
      notificationTypeId: contentDetails.notificationTypeId,
      notificationCategoryId: contentDetails.notificationCategoryId,
    })
  }

  ShowInvoicePatterns(notificationCatId){
    this.invoicePatterns = false;
    if(notificationCatId == 15){
      this.invoicePatterns = true;
    }
  }

}
