import { Injectable } from "@angular/core";
import { AppConfig } from "./app.config";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class JsonAppConfigService extends AppConfig {
  appconfig: AppConfig;
  constructor(private route: Router) {
    super();
  }

  load() {
    this.baseUrl = `${environment.apiUrl}`;
    this.payuUrl = `${environment.payuUrl}`;
    this.nsmCount = Number(`${environment.nsmCount}`);
    this.rsmCount = Number(`${environment.rsmCount}`);
    this.asmCount = Number(`${environment.asmCount}`);
    this.financeCount = Number(`${environment.financeCount}`);
    this.maxvehicleAge = Number(`${environment.maxvehicleAge}`);
    this.gCaptchaKey = `${environment.gCaptchaKey}`;
    this.gCaptchaSecret = `${environment.gCaptchaSecret}`;

    this.route.initialNavigation();
  }
}
