<div nxLayout="grid">

  <div nxRow>
    <div nxCol="4" nxColOffset="1">
      <nx-formfield>
        <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
        <span nxFormfieldPrefix>
          <nx-icon name="search" size="s"></nx-icon>
        </span>
        <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getColorList()">
          <nx-icon name="close" size="s"></nx-icon>
        </span>
      </nx-formfield>
    </div>
    <div nxCol="2" class="nx-margin-top-2xs">
      <button nxButton="primary small-medium" type="button" (click)="getColorList();">Search</button>
    </div>
    <div nxCol="4" class="nx-margin-top-2xs">
      <button (click)="toggleModal(false,0)" nxButton="secondary" type="button" style="float: right;">Add
        Color</button>

    </div>

  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable condensed nxSort (sortChange)="sortTable($event)">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              Color Id
            </th>
            <th nxHeaderCell nxSortHeaderCell="colorName">
              Color Name
            </th>

            <!-- <th nxHeaderCell>
              Created Date
            </th> -->
            <th nxHeaderCell nxSortHeaderCell="isActive">
              Status
            </th>
            <th nxHeaderCell>
              Edit
            </th>
            <th nxHeaderCell>
              Activate/Deactivate
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow *ngFor="let item of colorList">
            <td nxTableCell>{{item.colorId}}</td>
            <td nxTableCell>{{item.colorName}}</td>
            <!-- <td nxTableCell>{{item.createdDate}}</td> -->
            <td nxTableCell *ngIf="item.isActive =='1' else inActive">
              <nx-badge vibrant type="positive">Active</nx-badge>
            </td>
            <ng-template #inActive>
              <td nxTableCell>
                <nx-badge vibrant type="critical">In Active</nx-badge>
              </td>
            </ng-template>
            <td nxTableCell>
              <button nxIconButton="tertiary small">
                <nx-icon name="edit" size="s" (click)="toggleModal(true,item.colorId)"></nx-icon>
              </button>

            </td>
            <td nxTableCell *ngIf="item.isActive =='1'">
              <nx-switcher [checked]="true" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
            </td>
            <td nxTableCell *ngIf="item.isActive =='0'">
              <nx-switcher [checked]="false" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
    <div nxCol="6" >
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
        (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
    </div>
  </div>
</div>

<nx-modal #addColor [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="colorModalOpen"
  [nxShowCloseIcon]="false">
  <div *ngIf="!isSuccess">
    <form name='addcolor-form' [formGroup]="colorForm">

      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add Color</h1>
          <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Edit Color</h1>
        </div>
        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Color">
              <input nxInput formControlName="colorName" />
              <nx-error nxFormfieldError>
                <div *ngIf="f.colorName.errors?.required"><strong>Please note:</strong> this field is required!</div>
                <div *ngIf="f.colorName.errors?.pattern"><strong>Please note:</strong>Enter Valid Color Name.
                  <br>Allowed charcters are -_,/.&lt;space&gt; and Alpha numerics</div>
              
              </nx-error>
            </nx-formfield>
          </div>

        </div>

        <div nxRow>
          <div nxCol="12">
            <button (click)="addColorMaster()" [disabled]="colorForm.invalid" nxButton="primary" type="button"
              *ngIf="!EDIT_FLAG">Add</button>
            <button (click)="updateColorMaster()" [disabled]="colorForm.invalid" nxButton="primary" type="button"
              *ngIf="EDIT_FLAG">Update</button>
            <button (click)="toggleModal(false,0)" nxButton="secondary" type="button">Close</button>

          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify='start,around'>
      <div nxCol="12" id="successModalImage" class="success_image">
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Color added successfully</h3>
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Color updated successfully</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
          close
        </button>
      </div>
    </div>
  </div>


</nx-modal>
<!-- <app-footer></app-footer> -->
<!--Modal for confirmation -->
<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
  [nxShowCloseIcon]="false">
  <div nxRow nxRowJustify="start,around">
    <h3 nxHeadline="subsection-small">Confirmation</h3>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
    <div nxCol="12">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
    </div>
  </div>
  <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
    <div nxCol="6">

      <button (click)="changeColorStatus()" nxButton="block primary">
        Yes
      </button>
    </div>
    <div nxCol="6">
      <button (click)="confirmModalClose()" nxButton="block primary">
        No
      </button>
    </div>
  </div>
  <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
</nx-modal>
