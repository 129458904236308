export const environment = {
  production: false,
  apiUrl: "https://b2b2c-api-uat.awpassistance.in",
  payuUrl: "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js",
  nsmCount: 1,
  rsmCount: 5,
  asmCount: 25,
  financeCount: 1,
  gCaptchaKey: "6Lf1tpIcAAAAAGleIcXx5-qut76Mu7CJUzmypdy4",
  gCaptchaSecret: "6Lf1tpIcAAAAAAGRb8xMfgenumrtEKTWwXmxqE2f",
  maxvehicleAge: 6,
};
