import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { throwError } from "rxjs";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { ActivatedRoute, Router } from "@angular/router";
import { UserType } from "../core/userType.model";
import { Route } from "@angular/compiler/src/core";

@Component({
  selector: "app-workshopregistration",
  templateUrl: "./workshopregistration.component.html",
  styleUrls: ["./workshopregistration.component.css"],
})
export class WorkshopregistrationComponent implements OnInit {
  private _apiUrl: string;
  private clientId: number;
  workShopForm: FormGroup;
  closed = true;
  errorMessage: string;
  Messages: any;
  spinner = true;
  options = {
    autoClose: false,
    keepAfterRouteChange: false,
  };
  accpetedTypes: string = "application/pdf";
  public response: { dbPath: "" };
  checkUploadPath: string;
  panUploadPath: string;
  gstUploadPath: string;
  zoneList: any;
  login: any;
  asmUsers: any;
  stateList: any;
  userId: any;
  Id: any;
  navigation: boolean = false;
  cityList: any;
  @ViewChild("regtcaptchaRef") reCaptchaReg;
  GCaptcha: string;
  Gsize: string = "invisible";
  GCaptchaSecret: string;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private messageToastService: NxMessageToastService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this.GCaptcha = appConfig.gCaptchaKey;
    this.GCaptchaSecret = appConfig.gCaptchaSecret;
    this._apiUrl = appConfig.baseUrl;
    this.Messages = MESSAGE_CONSTS;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.Id = localStorage.getItem("IDENTIFIER");
    
  }

  ngOnInit() {
    this.formInit();
    this.getZones();
    // this.getASMUsers();
    this.getStateDetails();
    this.router.queryParams.subscribe((params) => {
      this.login = params.login;
    });
    if (this.login == "dealer") {
      this.workShopForm.get("userType").setValue(UserType.Dealer);
      this.workShopForm.get("workshopCode").clearValidators();
      this.workShopForm.get("workshopCode").setValue(null);
      this.workShopForm.updateValueAndValidity();
    } else {
      this.userId = localStorage.getItem("IDENTIFIER");
      if (this.userId) this.getDealerByCode();
    }
    if (this.Id) this.navigation = true;
  }

  formInit() {
    this.workShopForm = new FormBuilder().group({
      userType: [UserType.WorkShop],
      dealerCode: new FormControl("", [Validators.required]),
      workshopCode: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      workshopName: new FormControl("", [Validators.required,Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)]),
      workshopAddress: new FormControl("", [Validators.required,Validators.pattern(MESSAGE_CONSTS.ADDRESS_REGEX)]),
      serviceTaxNumber: [""],
      panNo: new FormControl("", [
        //Validators.required,
        Validators.pattern(MESSAGE_CONSTS.PAN_NUMBER_REGEX),
      ]),
      gstNumber: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.GSTNO_REGEX),
      ]),
      contactPerson: new FormControl("", [Validators.required,Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)]),
      contactNumber: new FormControl("", [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ]),
      primaryEmailIdContactPerson: new FormControl("", [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.EMAIL_REGEX),
      ]),
      contactPersonEmailId: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.EMAIL_REGEX),
      ]),
      bankAccountNo: new FormControl("", [
        Validators.pattern(MESSAGE_CONSTS.NUMBER_REGEX),
      ]),
      bankName: new FormControl(""),
      ifscCode: new FormControl("" ),
      beneficiaryName: new FormControl("", [Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)]),
      bankBranchAddress: new FormControl("", [Validators.pattern(MESSAGE_CONSTS.ADDRESS_REGEX)]),
      isActive: [-1],
      firstlogin: [1],
      CancelChequeImage: new FormControl(""),
      PanCardImage: new FormControl(""),
      GstCertificateImage: new FormControl(""),
      zoneId: ["", Validators.required],
      clientId: this.clientId,
      superUserId: ["", Validators.required],
      stateId: ["", Validators.required],
      cityId: ["", Validators.required],
      pincode: [
        "",
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.PINCODE_REGEX)],
      ],
      captcha: new FormGroup({
        secret: new FormControl(this.GCaptchaSecret, Validators.required),
        response: new FormControl(""),
        remoteip: new FormControl(""),
      }),
    });
    this.workShopForm.controls.cityId.disable();
  }

  get f() {
    return this.workShopForm.controls;
  }

  onSubmit() {
    this.workShopForm.patchValue({
      CancelChequeImage: this.checkUploadPath,
      PanCardImage: this.panUploadPath,
      GstCertificateImage: this.gstUploadPath,
      clientId: this.clientId,
    });
    this.workShopForm.updateValueAndValidity();

    this.closed = true;
    this.authService
      .post(this._apiUrl + "/api/v2/Workshops", this.workShopForm.value)
      .subscribe(
        (response) => {
          if (response) {
            this.reload();
            //this.resetFormControlValidation(this.workShopForm);
            this.messageToastService.open(this.Messages.WRKSHOP_SUCCESS_MSG, {
              context: "success",
              duration: 5000,
            });
          }
        },
        (error) => {
          this.handleError(error);
          this.reCaptchaReg.reset();
        }
      );
  }

  getDealerByCode() {
    this.authService
      .get(this._apiUrl + `/api/v1/Workshops/GetDealerDetailsbyCode`)
      .subscribe(
        (response) => {
          if (response.success) {
            this.workShopForm.patchValue({
              dealerCode: response.data[0].dealerCode,
              zoneId: response.data[0].zoneId,
            });
            this.workShopForm.updateValueAndValidity();
            this.onzoneChanges(response.data[0].zoneId);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public checkUploadFinished = (event) => {
    this.response = event;
    this.checkUploadPath = this.response.dbPath;
  };

  public panUploadFinished = (event) => {
    this.response = event;
    this.panUploadPath = this.response.dbPath;
  };

  public gstUploadFinished = (event) => {
    this.response = event;
    this.gstUploadPath = this.response.dbPath;
  };

  getStateDetails() {
    this.authService
      .get(
        this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId
      )
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
            // console.log("state list", this.stateList);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  //get all zones
  getZones() {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/client/${this.clientId}?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  //get all ASM/BD users for dealer
  getASMUsers() {
    this.authService
      .get(
        this._apiUrl +
          `/api/v1/Users/UserBaseType/${UserType.BD}/client/${this.clientId}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.asmUsers = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;
    }
    this.closed = false;
    this.messageToastService.open(this.errorMessage, {
      context: "info",
      duration: 5000,
    });
    return throwError(this.errorMessage);
  }


  onzoneChanges(id: any) {
    this.authService
      .get(
        this._apiUrl +
          `/api/v1/Users/GetUserDetailsfromZoneId/${id}/${this.clientId}?userBaseType=${UserType.BD}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.asmUsers = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getStateWiseCityList(stateId) {
    this.authService
      .get(this._apiUrl + "/api/v1/Cities/state/" + stateId)
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
            this.cityList = response;
            if (
              !this.cityList.find(
                (x) => x.id == this.workShopForm.get("cityId").value
              )
            ) {
              this.workShopForm.patchValue({
                cityId: null,
              });
            }
            this.workShopForm.controls.cityId.enable();
          }
        },
        (error) => {
          this.workShopForm.controls.cityId.disable();
          console.error(error);
        }
      );
  }

  getCityList() {
    this.authService
      .get(this._apiUrl + "/api/v1/Cities" + "?pageNumber=1&pageSize=50")
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public reload() {
    this.reCaptchaReg.reset();
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = "reload";
    this.route.navigate(["/registration"], {
      queryParams: { login: this.login },
    });
  }

  public submit(captchaResponse: string): void {
    if (captchaResponse !== null) {
      this.workShopForm.patchValue({
        captcha: {
          response: captchaResponse,
        },
      });
      this.onSubmit();
    }
  }
}
