import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { AppConfig } from "src/config/app.config";
import { AuthService } from "../core/auth/auth.service";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { PolicyStatus } from "../core/policyStatus.model";
import * as XLSX from "xlsx";

@Component({
  selector: "app-policysale-monthly-detailed-report",
  templateUrl: "./policysale-monthly-detailed-report.component.html",
  styleUrls: ["./policysale-monthly-detailed-report.component.css"],
})
export class PolicysaleMonthlyDetailedReportComponent implements OnInit {
  @Input() month: moment.Moment;
  @Input() year: moment.Moment;

  reportSearchForm: FormGroup;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
  }
  ngOnInit(): void {}

  loadReport() {
    this.pageNumber = 1;
    this.getReportData();
  }

  getReportData() {
    this.authService
      .getList(
        this._apiUrl +
          `/api/v2/Report/MonthlyDetailedPolicySaleReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&udf1=${this.month}&udf2=${this.year}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  exporttoexcel() {
    this.pageNumber = -1;
    this.getExportData();
  }

  getExportData() {
    this.authService
      .getList(
        this._apiUrl +
          `/api/v2/Report/MonthlyDetailedPolicySaleReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&udf1=${this.month}&udf2=${this.year}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach((element) => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.srNo = item.srNo;
    data.rsm = item.rsm;
    data.asm = item.asm;
    data.dealerCode = item.dealerCode;
    data.dealerName = item.dealerName;
    data.state = item.state;
    data.city = item.city;

    for (let i = 1; i <= 31; i++) {
      let indexItem = "d" + i.toString();
      data[indexItem] = item[indexItem];
    }
    data.total = item.total;
    this.exportdata.push(data);
  }

  bindexcel() {
    /* json data is passed over here */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    ws.A1.v = "Sr.No";
    ws.B1.v = "RSM";
    ws.C1.v = "Area Manager";
    ws.D1.v = "Dealer Code";
    ws.E1.v = "Dealer Name";
    ws.F1.v = "State";
    ws.G1.v = "City";

    let Cells: string[] = [
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
      "AL",
    ];
    for (let i = 0; i < 31; i++) {
      let indexItem = Cells[i] + "1";
      ws[indexItem].v = (i + 1).toString();
    }

    ws.AM1.v = "Total";

    XLSX.utils.book_append_sheet(wb, ws, "PolicyDetailedMonthlySaleReport");
    /* save to file */
    this.fileName =
      "PolicyDetailedSaleMonthlyReport_" +
      new Date(Date.now()).toLocaleString().split(",")[0] +
      ".xlsx";
    XLSX.writeFile(wb, this.fileName);
  }
}
