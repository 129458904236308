import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { PolicyStatus } from "../core/policyStatus.model";
import { NxMessageToastService } from '@aposin/ng-aquila/message';
import * as moment from 'moment';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomErrorHandler } from '../core/custom-error-handler';


@Component({
  selector: 'app-policyrequestlist',
  templateUrl: './policyrequestlist.component.html',
  styleUrls: ['./policyrequestlist.component.css']
})
export class PolicyrequestlistComponent implements OnInit {

  requestType: any;
  _apiUrl: string;
  clientId: number;
  policyRequestList: any = [];
  policyView: boolean = false;
  policyStatus: any;
  //policyDetails = {};
  policyDetails: any = {}
  policyModifiedDetails: any = {};
  policyStatusDetails: any = {};
  policyRequestLogStatusDetails: any = {};
  id: number;
  policyNo: number;
  requestLogId: number;
  stateList = [];
  cityList = [];
  stateName;
  cityName;
  stateM_Name;
  cityM_Name;
  isSuccess: boolean = false;
  successMsg: string = "Processing......";
  searchTerm: any = "";
  policyStartDate:moment.Moment;
  policyEndDate:moment.Moment;
  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  userTypeId: number;
  userId: number;
  transactionId: any;
  requestLogTransactionId: any;
  enableClose: boolean= false;
  
  prevPage() {
    this.pageNumber--;
    this.getPolicyRequestList();
  }

  nextPage() {
    this.pageNumber++;
    this.getPolicyRequestList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getPolicyRequestList();
  }
  /**Pagination end */

  constructor(private authService: AuthService, private appConfig : AppConfig,
    private messageToastService: NxMessageToastService,private errorHandler: CustomErrorHandler) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.policyStatus = PolicyStatus;
    this.requestType = this.policyStatus.CancelationApprovalPending.toString();
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")))
  }

  ngOnInit() {
    this.getPolicyRequestList();
    this.getStateList();
    this.getCityList();
  }

  changeRequestType(requestType) {
    this.requestType = requestType;
    this.getPolicyRequestList();
  }

  getPolicyRequestList() {
    this.policyRequestList = [];
    var policyStartDate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var policyEndDate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');

    this.authService.getList(this._apiUrl + `/api/v1/PolicyDetails/policyStatus/${this.requestType}`+
    "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm + "&startDatepicker=" + policyStartDate + "&endDatepicker=" + policyEndDate)
    .subscribe(response => {

        this.policyRequestList = response.body;
        let pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;

      }, (error) => {
        console.error(error);
      });
  }

  toggleModal(id, policyNo,transactionId) {
    this.policyView = true;
    this.id = id;
    this.policyNo = policyNo;
    this.transactionId = transactionId
    this.getPolicyDetails(this.transactionId);
    this.getPolicyModifiedDetails(this.transactionId);

  }

  closeModal() {
    this.policyView = false;
    this.policyDetails = {};
    this.policyModifiedDetails = {};
    this.policyStatusDetails = {};
    this.isSuccess = false;
    this.getPolicyRequestList();
  }
  getPolicyDetails(id) {
    this.authService.get(this._apiUrl + '/api/v2/PolicyDetails/' + id).subscribe(response => {
      if (response) {

        if (response.stateId) {

          for (let ele of this.stateList) {
            if (ele.id == response.stateId) {
              this.stateName = ele.stateName;
            }
          }

        }
        if (response.cityId) {
          for (let ele of this.cityList) {
            if (ele.id == response.cityId) {
              this.cityName = ele.cityName;
            }
          }
          //  this.cityName = this.cityList[response.cityId].cityName;
        }

        this.policyDetails = response;
      }
    }, (error) => {
      console.error(error);
    });
  }
  getPolicyModifiedDetails(policyNo) {

    this.authService.get(this._apiUrl + '/api/v1/PolicyRequestLogs/policy/' + policyNo).subscribe(response => {

      for (var resp of response) {
        if (resp.status == this.policyStatus.CancelationApprovalPending || resp.status == this.policyStatus.ModificationApprovalPending) {
          this.policyModifiedDetails = resp;
          this.requestLogId = resp.id;
          this.requestLogTransactionId = resp.transactionId;
          if (resp.stateId) {
            // this.stateM_Name = this.stateList[resp.stateId].stateName;
            for (let ele of this.stateList) {
              if (ele.id == resp.stateId) {
                this.stateM_Name = ele.stateName;
              }
            }
          }
          if (resp.cityId) {
            //this.cityM_Name = this.cityList[resp.cityId].cityName;
            for (let ele of this.cityList) {
              if (ele.id == resp.cityId) {
                this.cityM_Name = ele.cityName;
              }
            }
          }
        }
      }


    }, (error) => {
      console.error(error);
    });
  }

  approveRequest() {
    if (this.requestType == this.policyStatus.CancelationApprovalPending) {
      this.policyStatusDetails = {
        id: this.transactionId,
        statusId: this.policyStatus.Cancel
      }
      this.policyRequestLogStatusDetails = {
        id: this.requestLogTransactionId,
        statusId: this.policyStatus.Cancel
      }
      this.updatePolicyStatus();
    } else if(this.requestType == this.policyStatus.ModificationApprovalPending){
      this.authService.put(this._apiUrl + '/api/v1/Unity/ModifyUnitydata/',this.requestLogTransactionId ).pipe(map((response) => {
        if(response.success)
        {
          this.isSuccess = true;
          this.enableClose = false;
          this.successMsg = "Unity/APD Modified...";
        }
        else
        {
          this.closeModal();
          this.errorHandler.handleCustomError(response.responsemsg);
        }
      })
      //update policy table directly 
      ,mergeMap(()=>this.authService.put(this._apiUrl + '/api/v1/PolicyDetails/ModificationApproval/' + this.requestLogTransactionId + '/' + this.transactionId, '').pipe(catchError((error) => this.errorHandler.handleCustomError('Something went wrong modifing policy!'))))
      ,map((response) => {
        if(response.success)
        {
          this.successMsg = "Send for policy updation...";
        }
        else
        {
          this.closeModal();
          this.errorHandler.handleCustomError(response.responsemsg);
        }
       })// Trigger call to create modified policy document
       , mergeMap(() => this.authService.export(this._apiUrl + `/api/v1/DownLoadPage/policyNo/${this.transactionId}`).pipe(catchError((error) => this.errorHandler.handleCustomInfo('Generating policy document is failed, Please download from Manage Policy !'))))  
       , map((response) => {
         if(response != null){
         }
        })
 
       // Trigger call to send policy document
       , mergeMap(() => this.authService.get(this._apiUrl + `/api/v1/PolicyDetails/sendModifiedPolicyMail/policyNo/${this.transactionId}`).pipe(catchError((error) => this.errorHandler.handleCustomInfo('Policy document failed to send via Email,Please Download Later!')))) 
       , map((response) => {
          if(!response.success)
          {
            this.errorHandler.handleCustomError(response.responsemsg);
          }else
          {
            this.enableClose = true;
            this.successMsg = "Policy Modification Completed Successfully!";
          }
        })
      
      ).subscribe(response => {
      }, (error) => {
        //this.errorHandler.handleCustomError('Something went wrong modifing policy!')
        this.closeModal();
        console.error(error);
      });
    }

  }

  rejectRequest() {
    this.policyStatusDetails = {
      id: this.transactionId,
      statusId: this.policyStatus.Active
    }
    this.policyRequestLogStatusDetails = {
      id: this.requestLogTransactionId,
      statusId: this.policyStatus.Active
    }

    this.updatePolicyStatus();
  }

  updateRequestLogStatus() {
    this.authService.put(this._apiUrl + '/api/v1/PolicyRequestLogs/StatusUpdate', this.policyRequestLogStatusDetails).subscribe(response => {
      // this.closeModal();
      this.isSuccess = true;
      this.enableClose = true;
      if(this.policyStatusDetails.statusId==this.policyStatus.Active)
      {
        this.successMsg = "Policy Request Rejected."
      }
      else if(this.policyStatusDetails.statusId==this.policyStatus.Cancel)
      {
        this.successMsg = "Policy Request cancelled."    
      }
    }, (error) => {
      console.error(error);
    });
  }
  updatePolicyStatus() {
    this.authService.put(this._apiUrl + '/api/v1/PolicyDetails/StatusUpdate', this.policyStatusDetails).subscribe(response => {
      this.updateRequestLogStatus();
    }, (error) => {
      console.error(error);
    });
  }

  getStateList() {
    this.authService.get(this._apiUrl + '/api/v1/States?pageNumber=-1').subscribe(response => {
      if (response) {

        this.stateList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  getCityList() {
    this.authService.get(this._apiUrl + '/api/v1/Cities?pageNumber=-1').subscribe(response => {
      if (response) {

        this.cityList = response;
        //console.log('city list', this.cityList)
      }
    }, (error) => {
      console.error(error);
    });
  }

}
